.follow-button-wrapper {
  @media screen and (min-width: 600px) {
    .notification-bell {
      height: 48px;
      width: 48px;
      margin: 0 15px 0 0;
    }
    .follow-button {
      width: 120px !important;
      --padding-start: 48px;
      --padding-end: 48px;
    }
  }
}
