.address-form {

  .side-by-side {
    padding: 5px 0;
    div:first-child {
      margin-right: 10px;
    }
  }
  .hide-address-line {
    ion-item {
      --padding-start: 0;
      ion-checkbox {
        margin: 0 2vw 0 0;
      }
    }
    p {
      margin: 0;
      font-size: 3vw;
    }
  }
}
