.posts-swiper{
    @media screen and (min-width: 600px) {
        .swiper-navigation {
            width: 600px;
            padding: 0 60px;
        }
        .swiper {
            .swiper-slide {
                .saved-posts-content-list {
                    padding-top: 24px;
                }
            }
        }
    }
}
