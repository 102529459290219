.desktop-file-upload {
  display: none;
}
.asset-uploader {
  position: relative;
  cursor: pointer;
}
.drop-overlay {
  opacity: 0;
  transition: opacity 0.5s;
  border: 1px dashed black;
  position: absolute;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 15px;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  &.visible {
    opacity: 1;

  }
}
