.sign-up-page {
  ion-content {
    .flex-wrapper {
      .sign-up-disclaimer-wrapper {
        .sign-up-disclaimer {
          ion-icon {
            @media screen and (min-width: 640px){
              font-size: 64px;
              width: 160px;
              margin: 0 16px 0 0;
            }
          }
        }
      }
    }
  }
}
