.user-details-wrapper {
  @media screen and (min-width: 600px) {
    .profile-image {
      width: 72px;
      height: 72px;
    }
    .entity-details-text {
      padding-left: 18px;
    }
    ion-button {
      width: 126px;
      height: 48px;
      font-size: 18px;
    }
  }
}
