.business-header-setup {
  @media screen and (min-width: 600px) {
    ion-content {
      --padding-start: 48px;
      --padding-end: 48px;
      --padding-top: 72px;
    }
    h4 {
      padding-bottom: 48px;
    }
  }
  .header-images {
    .featured-images {
      @media screen and (min-width: 854px) {
        height: 218px;
      }
      @media screen and (min-width: 480px) {
        margin: 15px 0 30px;
      }
    }

    .asset-uploader {
      @media screen and (min-width: 480px) {
        ion-icon {
          border-radius: 75px;

          &.profile-image-icon {
            bottom: 3.125vw;
          }
        }
      }
    }
  }
  .bottom-sticky-section {
    @media screen and (min-width: 600px) {
      max-width: 450px;
    }
  }
}
