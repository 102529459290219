.category-search-bar {
  position: relative;
  min-height: 44px;
  ion-list {
    z-index: 1000;
    position: absolute;
    top: 105%;
    left: 0;
    right: 0;
    ion-item {
      --padding-start: 0;
    }

    &.visible {
      display: block;
    }
    &.hidden {
      display: none;
    }
  }
}
