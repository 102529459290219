.confirmation-page-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  justify-content: space-between;
  p {
    font-size: 3.5vw;
    color: black;
  }
  h3 {
    color: var(--ion-color-medium);
    font-weight: bold;
  }
  hr {
    background: var(--ion-color-light-shade);
    height: 1px;
    max-width: 35vw;
    margin: 0 auto;
  }
  .order-summary {
    padding: 0 5vw;
  }
}
