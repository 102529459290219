#welcome-section {
  .terms-page {
    ion-content {
      --background: white;
      h3 {
        font-size: 22px;
      }
    }
  }
}
