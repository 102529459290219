.verification-code-entry {
  h4 {
    font-size: 8vw;
  }
  .phone-number-display {
    a {
      margin: 0 4vw;
      cursor: pointer;
    }
  }
  ion-input {
    border: none;
    width: 12ch;
    background:
            repeating-linear-gradient(90deg,
                    black 0,
                    black 1.5ch,
                    transparent 0,
                    transparent 1.75ch)
            0 90%/86% 2px no-repeat;
    color: black;
    --padding-start: .25ch;
    font: 4ch consolas, monospace;
    letter-spacing: .75ch;
    overflow:hidden;
  }
  .submit-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4vw auto;
    .resend {
      cursor: pointer;
    }

    ion-icon {
      color: var(--ion-color-light-shade);
      &.valid {
        color: var(--ion-color-primary);
      }
    }
  }
}

@import "responsive";
