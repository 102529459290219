
#welcome-section {
  .sign-up-page {
    .name-phone-signup {
      h3 {
        @media screen and (min-width: 333px) {
          padding-bottom: 40px;
        }
        @media screen and (min-width: 514px) {
          font-size: 36px;
        }
      }
      ion-item {
        @media screen and (min-width: 640px) {
          border: 2px var(--ion-color-primary) solid;
          margin-bottom: 20px;
          --padding-start: 10px;
        }
        @media screen and (min-width: 685px) {
          &.age-checkbox {
            p {
              font-size: 24px;
            }
          }
        }
      }
      .name-email-form-content {
        ion-input {
          @media screen and (min-width: 480px) {
            font-size: 24px;
          }
          @media screen and (min-width: 640px) {
            --padding-start: 10px;
          }
        }
        ion-icon {
          @media screen and (min-width: 514px) {
            font-size: 36px;
          }
          @media screen and (min-width: 640px) {
            margin: 0 0 0 10px;
          }
        }
        .disclaimer {
          @media screen and (min-width: 640px) {
            margin: 0 20px;
          }
          @media screen and (min-width: 685px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 640px) {
  #welcome-section {
    .sign-up-page {
      .name-phone-signup {
        .name-email-form-content {
          ion-icon {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
}
