#album-page {
	h2{
		font-weight: bold;
		margin-top: 0;
		padding-bottom: 5vw;
	}
	p{
		font-weight: bold;
	}
	ion-header {
		ion-button{
			--background: transparent;
		}
	}
	.album-post-list{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		.post-item{
			flex-basis: 45%;
			padding-bottom: 15px;
			--padding-start: 0;
			--padding-end: 0;
			--inner-padding-start: 0;
			--inner-padding-end: 0;
			ion-icon {
				position: absolute;
				font-size: 1.4em;
				top: 5%;
				right: 5%;
				background-color: var(--ion-color-dark);
				padding: 5px;
				border-radius: 50%;
				color: var(--ion-color-light);
				&.saved-icon {
					color: var(--ion-color-primary);
				}
			}
			.post-content {
				margin: 0 auto;
			}
			p{
				display: block;
				font-weight: bold;
				font-size: 1rem;
				margin: 5px 0 0;
				text-align: left;
				width: 100%;
				color: var(--ion-color-medium);
			}
			.album-post-thumbnail, .album-post-thumbnail-placeholder{
				width: 40vw;
				height: 40vw;
				align-self: center;
			}
		}
	}
}

#album-page-toast::part(message) {
	display: grid;

}

@import "responsive";
