.organization-creation-content{
    --padding-top: 0;
    h4, p {
        margin-top: 2em;
    }
    p{
        line-height: 1.5em;
    }
    ion-router-link{
        --color: var(--ion-color-secondary);
    }
    .results-children-wrapper{
        min-height: 250px;
    }
    .organization-creation-footer{
        padding-top: 2em;
        ion-button{
            font-size: 1.2em;
        }
        p{
            display: block;
            font-size: .8em;
            max-width: 275px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            ion-router-link{
                font-weight: bold;
                --color: var(--ion-color-dark);
            }
        }
    }
}