.home-desktop-menu.start {
  max-width: 300px;
  ion-content {
    --padding-top: 0;
  }
  ion-item {
    font-weight: bold;
    font-size: 1.2rem;
    --color-hover: var(--ion-color-primary);
    ion-icon {
      margin: 0 10px 0 0;
    }
    &.active, &.active ion-icon {
      color: var(--ion-color-primary);
    }
  }
  ion-footer{
    padding: 20px 0;
  }
  .desktop-logo {
    height: 35px;
    width: auto;
    display: block;
    margin: 20px auto 20px 40px;
  }
}
