.base-location-edit-page {
  .organization-header {
    ion-toolbar {
      --color: var(--ion-color-medium);
      ion-title,
      ion-back-button {
        color: var(--ion-color-medium);
        --color: var(--ion-color-medium);
      }
    }
  }
  .side-by-side {
    ion-icon {
      font-size: 7.5vw;
      width: 12.5%;
    }
    .editable-content {
      width: 85%;
      ion-textarea,
      ion-input {
        --placeholder-font-style: italic;
        border-bottom: 3px var(--ion-color-medium) solid;
      }
      ion-list {
        ion-item {
          --padding-start: 0;
          --padding-end: 0;
        }
      }
      ion-input {
        margin: 0 0 3.125vw;
      }

      ion-button.ion-color-light {
        width: auto;
        margin: 3.125vw 1.5625vw;
        --ion-color-contrast: var(--ion-color-medium) !important;
      }
    }
  }
}
