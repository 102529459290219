.no-posts-wrapper {
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--ion-color-primary);
  min-height: 65vh;
  display: flex;
  margin-top: 16vh;
  .no-posts-details {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    flex-grow: 1;
    margin: 0 6vw;

    ion-icon {
      font-size: 20vw;
      color: white;
      margin: 0 auto 5vw;
    }

    h4 {
      font-size: 7vw;
      color: white;
    }

    p {
      color: white;
    }
  }
}
ion-content.md {
  .no-posts-wrapper {
    margin-top: 10vh;
    min-height: 71vh;
  }
}
@import "responsive";