.follow-button-wrapper {
  display: flex;
  align-items: center;
  .notification-bell {
    height: 8vw;
    width: 8vw;
    margin: 0 2.5vw 0 0;
  }
  .follow-button {
    width: 20vw !important;
    text-transform: none;
    --border-radius: 4px;
    max-height: 35px;
    --padding-start: 8vw;
    --padding-end: 8vw;
  }
  &.on-black {
    .following::part(native) {
      border-color: white !important;
      color: white;
    }
  }
}
@import "responsive";
