.business-location-content-details {
  .location-address-info {
    ion-button {
      @media screen and (min-width: 600px) {
        margin: 0 24px 24px;
      }
    }
  }
}
.location-action-sheet {
  .action-sheet-group {
    .action-sheet-button {

      .action-sheet-button-inner {
        @media screen and (min-width: 600px) {
          font-size: 24px;
          ion-icon {
            font-size: 36px;
          }
        }
      }
    }
  }
}
