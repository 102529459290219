.business-header-setup {
  ion-content {
    --padding-start: 8vw;
    --padding-end: 8vw;
    --padding-top: 12vw;
    --padding-bottom: 55px;
  }
  h4 {
    color: var(--ion-color-medium);
    padding-bottom: 8vw;
  }
  .header-images {
    text-align: center;
    padding-bottom: 2px;
    .featured-images {
      height: 25.5vw;
      margin: 3.125vw 0 6.25vw;
      > div {
        width: 32%;
      }
      .placeholder {
        background-image: url("/assets/add-image-placeholder.png");
      }
    }

    .asset-uploader {
      position: relative;
      margin: 0 auto;
      display: inline-block;
      ion-icon {
        background: white;
        border-radius: 75px;
        padding: 7px;
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        margin: auto;
        box-shadow: .4px .6px 3px -.16px #000000;
        &.profile-image-icon {
          right: 0;
          bottom: 15px;
          left: auto;
        }
      }
    }
  }
  .bottom-sticky-section {
    display: block;
    max-width: 75vw;
    margin: 0 auto 2em;
  }
}
@import "responsive";
