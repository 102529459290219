.news-feed {
  --padding-start: 10px;
  --padding-end: 10px;
  .feed-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    @media (pointer: fine) {
      padding-bottom: 36px;
    }
  }
}
