.not-logged-in-header {
  a {
    color: var(--ion-color-medium);
    width: 40px;
    text-align: center;
  }
}
.not-logged-in-content {
  > div {
    text-align: center;
    padding: 10px 50px;
    h1 {
      margin: 30px auto;
    }
  }
}
