.schedule-section {
  @media screen and (min-width: 600px) {
    padding: 0 0 30px;
  }
  .schedule-title {

    span {
      @media screen and (min-width: 600px) {
        font-size: 24px;
      }
      @media screen and (min-width: 512px) {
        padding: 0 8px;
      }
      @media screen and (min-width: 600px) {
        margin: 0 auto 21px;
      }
    }
  }

  ion-item {
    @media screen and (min-width: 514px) {
      font-size: 18px;
    }
  }
}
