.comment-input {
  border-top: 1px solid var(--ion-color-light);
  padding: 3px 0 6px;
  --padding-end: 15px;
  .profile-image {
    width: 11.25vw;
    height: 11.25vw;
  }
  ion-textarea {
    border: 1px solid var(--ion-color-light-shade);
    padding: 0;
    border-radius: 5px;
    margin: 3px 0;
    textarea {
      padding: 5px !important;
    }
  }
}
@import "responsive";
