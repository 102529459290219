.side-by-side {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  &.desktop-only {
    flex-direction: column;
  }
  @media screen and (min-width: 800px) {
    &.desktop-only {
      flex-direction: row;
    }
  }

  > * {
    width: 48%;
    flex-grow: 1;
  }
}
