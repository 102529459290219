.post-text-content {
  text-align: left;
  margin: 8px 0;
  .post-content-wrapper {
    position: relative;
    .see-more-link {
      right: 0;
      bottom: 0;
      position: relative;
      font-weight: bold;
      float: right;
    }
  }
  p {
    margin: 0;
    padding: 0;
    &:after {
      content:"";
      display:inline-block;
      width:0px;
      clear: both;
    }
    &.user-post-text {
      font-style: italic;
      padding: 8px 0;
    }
  }
  p, h4, h5, h6, span {
    color: white !important;
    background-color: transparent !important;
    white-space: pre-line;
  }
  em {
    font-family: 'chewyregular';
    color: var(--ion-color-primary) !important;
  }
}
.user-post {
  .post-text-content {
    p, h4, h5, h6, span {
      white-space: normal;
    }
  }
}
