@media screen and (min-width: 992px) {
	.album-list{
		justify-content: flex-start;
		.album-list-item {
			flex-basis: 25%;
			padding: 0 10px;
			.album-item-content {
				width: 10vw;
				margin: 15px auto;
			}
		}
	}
}
