@media screen and (min-width: 600px) {
  .location-list-page {
    ion-content {
      --padding-bottom: 30px;
    }
    ion-item {
      margin: 0 0 30px;
      .item-content {
        p, h6 {
          font-size: 24px;
        }
      }
    }
  }

}