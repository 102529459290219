.permission-prompt {
  .location-icon {
    @media screen and (min-width: 480px) {
      height: 168px;
      width: 168px;
      padding: 24px;
    }
    @media screen and (min-width: 600px) {
      margin: 72px auto 48px;
    }
  }
  .sub-header {
    @media screen and (min-width: 480px) {
      margin: 24px;
    }
    @media screen and (min-width: 480px) {
      font-size: 28px;
    }
  }
  ion-button {
    @media screen and (min-width: 600px) {
      margin: 72px 0 0 !important;
    }
  }
  .disclaimer {
    @media screen and (min-width: 480px) {
      margin: 48px 24px;
    }
  }
}

