#following-page {
  .follower-item {
    p {
      font-size: 3vw;
    }
    --padding-start: 1px;
    .profile-image {
      margin-inline-end: 12px;
    }

    .follow-button-wrapper {
      margin-inline-start: 12px;
    }
  }
}

@import "responsive";
