
@media screen and (min-width: 600px) {
  .post-actions-wrapper {
    .post-actions {
      ion-icon {
        font-size: 50px;
      }

      .post-action-callout {
        width: 69px;
        height: 66px;

        &.save ion-icon {
          font-size: 45px;
          margin-top: 3px;
        }
      }
    }
  }
}
