.notification-permission-content {
  ion-icon {
    width: 10vw;
    height: 10vw;
  }
  p {
    font-size: 8vw;
    margin: 4vw 0 8vw;
  }
  ion-button {
    max-width: 80%;
    &::part(native) {
      color: var(--ion-color-medium);
      font-weight: bold;
    }
  }
}
#welcome-section .notification-permission-content {
  p {
    font-size: 8vw;
  }
}
@import "responsive";
