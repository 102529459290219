.send-invite-modal {
    ion-header ion-button {
        --background: transparent;
    }
    .invite-friends {
        padding: 15% 3% 0;

        h4 {
            font-size: 10vw;
            color: var(--ion-color-medium);
            font-weight: normal;
        }

        p {
            font-size: 4vw;
        }
    }

    .invite-link {
        width: 100%;
        bottom: 10vw;

        ion-button {
            display: block;
            max-width: 80vw;
            margin: 0 auto;
            font-weight: bold;
            font-size: 1.2em;
        }
    }
}

@import 'responsive';
