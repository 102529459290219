.plt-mobileweb,
.plt-desktop {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

// These are the organization breakpoints
@media screen and (max-width: 800px) {
  .desktop-only {
    display: none !important;
  }
}
@media screen and (min-width: 801px) {
  .mobile-only {
    display: none !important;
  }
}

// Public breakpoints
.hide-in-desktop {
  @media screen and (min-width: 992px) {
    display: none !important;

  }
}
.hide-in-mobile {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}
