.browse-page {
  @media screen and (min-width: 600px) {
    ion-header {
      padding-top: 18px;
    }
    ion-content {
      ion-searchbar {
        padding-left: 30px;
        padding-right: 30px;
      }
      ion-item {
        margin: 30px 0;
      }
      .results-wrapper {
        --padding-bottom: 108px;
      }
    }
    .no-results,
    .no-locations {
      padding: 48px 39px;
      ion-icon{
        font-size: 300px;
        padding: 36px 0;
      }
    }
    .results-section {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 30px;
      }
    }
  }
}
