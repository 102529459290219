#profile-editor {
  .profile-image {
    @media screen and (min-width: 576px) {
      margin: 18px auto;
    }
    @media screen and (min-width: 600px) {
      width: 225px;
      height: 225px;
    }
  }
  .grey-background-section {
    @media screen and (min-width: 576px) {
      margin: 18px auto;
    }
    ion-radio-group {
      ion-item {
        ion-label {
          @media screen and (min-width: 548px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
