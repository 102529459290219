.referral-tutorial-modal {
  --max-width: 90%;
  --min-width: 70%;
  --backdrop-opacity: 0.6;
  padding: 20px;
  ion-content {
    --padding-top: 20px;
    --offset-bottom: unset !important;
    overflow: auto !important;
  }
  h4 {
    text-align: center;
    max-width: 70%;
    margin: 5px auto 0;
    font-weight: normal;
    font-size: 6.25vw;
  }
  p {
    font-size: 4.375vw;
  }
  ol {
    color: black;
    font-weight: bold;
    padding-left: 3em;
    li {
      font-size: 5vw;
    }
  }
  h3 {
    font-family: 'chewyregular';
    font-size: 6.875vw;
    color: var(--ion-color-secondary);
    margin-left: auto;
    margin-right: auto;
  }
  .disclaimer {
    font-size: 5vw;
    max-width: 75%;
    margin: 20px auto;
    text-align: center;
  }
  ion-button {
    width: 60%;
    display: block;
    --border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}
@import "responsive";
