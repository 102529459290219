#profile-editor {
  .profile-image {
    width: 37.5vw;
    height: 37.5vw;
    margin: 3.125vw auto 3.125vw;
  }
  .grey-background-section {
    margin: 3.125vw auto;
    text-align: left;
    h4 {
      text-align: center;
    }

    ion-radio-group {
      ion-item {
        --background: none;
        --padding-start: 0;
        ion-label {
          font-size: 4.375vw;
        }
        ion-radio {
          -webkit-margin-end: 12px;
          margin-inline-end: 12px;
        }
      }
    }
  }
}
@import "responsive";
