#account-page {
  @media screen and (min-width: 600px) {
    .account-header{
      .account-stripe{
        height: 75px
      }
      .profile-image {
        height: 144px;
        width: 144px;
        margin: -72px auto 0;
        &.is-scrolled{
          height: 96px;
          width: 96px;
        }
      }
    }
    .settings-icon {
      font-size: 42px;
    }
    #account-details {
      padding-top: 150px;
      .profile-image{
        height: 144px;
        width: 144px;
        margin: -72px auto 0;
        &.is-scrolled{
          height: 96px;
          width: 96px;
        }
      }
    }

    .posts-swiper{
      padding-bottom: 120px;
    }
    .user-invite{
      .user-invite-friends-bar{
        padding: 18px 36px;
        ion-button{
          max-width: 300px;
        }
      }
    }
    .posts-swiper{
      padding-bottom: 20vw;
    }
    .accepted-link{
      color: var(--ion-color-base);
    }
  }
  @media screen and (min-width: 500px) {
    .invite-friends {
      margin-top: 25px;
    }
    .side-by-side {
      padding-top: 150px;
    }
    #add-bio-link {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}
