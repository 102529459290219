.business-header {
  .featured-images {
    @media screen and (min-width: 606px){
      height: 200px;
    }
  }
  .business-details {

    @media screen and (min-width: 512px) {
      padding: 24px 0 0;
      .business-info-line {
        padding-bottom: 24px;
      }
    }
    .edit-business-line {
      @media screen and (min-width: 711px) {
        padding-bottom: 12px;
      }
    }
  }
}
