.organization-header {
  ion-toolbar {
    ion-title {
      @media screen and (min-width: 640px) {
        padding-left: 96px;
        padding-right: 96px;
      }
      @media screen and (min-width: 731px) {
        font-size: 32px;
      }
    }
  }
}
