ion-header.logged-out {
  ion-toolbar {
    @media screen and (min-width: 600px){

      ion-icon{
        color: var(--ion-color-primary);
        width: 48px;
        height: 60px;
      }
      .swipe-logo {
        max-width: 120px;
      }
    }
  }
}
