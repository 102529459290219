.post-card-action-sheet-options {

  .action-sheet-group {
    .action-sheet-button {
      .action-sheet-button-inner {
        color: var(--ion-color-medium);
      }
      &.action-sheet-delete .action-sheet-button-inner {
        color: var(--ion-color-danger);
      }
    }
  }
}
