.locations-list {
  padding: 0 15px;
  .locations-header {
    display: flex;
  }
  ion-item {
    --padding-start: 0;
    font-size: 4vw;
    .profile-image {
      margin-right: 10px;
    }
  }
  .add-location-button {
    color: var(--ion-color-success);
    cursor: pointer;
  }
}
@import "responsive";
