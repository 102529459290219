.dummy-tab-content {
  --padding-bottom: var(--ion-safe-area-bottom);
  .content-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 16vw;
  }
  .dummy-tabs {
    bottom: var(--ion-safe-area-bottom);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 1vw;
    ion-icon {
      cursor: pointer;
      font-size: 8vw;
      color: var(--ion-color-medium);
      &.primary-tab{
        padding: 2px;
        border-radius: 50%;
        font-size: 12vw;
        height: 12vw;
        width: 11.5vw;
        margin: 0;
        color: var(--ion-color-primary);
      }
    }
  }
}

@import "responsive";
