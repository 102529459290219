.business-list-page {
  ion-item {
    .item-content {
      p, h6 {
        @media screen and (min-width: 600px) {
          font-size: 24px;
        }
      }
    }
  }
}
