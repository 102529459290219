.activity-tabs-content {
  ion-tab-bar {
    padding: 0;
    --border: 4px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2))));
    ion-tab-button {
      --color: black;
      --color-selected: white;
      ion-label {
        width: 110px;
        border-radius: 50px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--ion-color-light);
      }
      &.tab-selected ion-label {
        background: var(--ion-color-primary);
      }
    }
  }
}
