.underlined-input {
  ion-datetime,
  ion-textarea,
  ion-input {
    --padding-start: 10px;
    border: transparent;
    border-bottom: 3px var(--ion-color-medium) solid;
  }
  ion-label {
    font-weight: 600;
  }
}
