.next-arrow {
  font-size: 16vw;
  color: var(--ion-color-light);
  float: right;
  &.valid{
    color: var(--ion-color-primary);
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    font-size: 96px;
  }
}
