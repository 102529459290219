.post-item {
  @media screen and (min-width: 600px) {
    .post-item-details {
      .post-date {
        font-size: 18px;
      }
    }
  }
  @media screen and (min-width: 600px) {
    --padding-top: 18px;
    --padding-bottom: 18px;
    .post-item-details {
      .likes-count {
        font-size: 18px;
        ion-icon {
          font-size: 24px;
        }
      }
    }
  }
}
