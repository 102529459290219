.categories-editor {
  .industry-input {
    margin: 0 0 6.25vw !important;

    .native-input[disabled] {
      opacity: 1;
    }
  }
  .categories-list {
    overflow: visible;

    ion-item {
      overflow: visible;
    }
    .category-search-bar {
      flex: 1;
    }
    ion-icon {
      font-size: 32px !important;
      width: 32px !important;
    }
  }
}
