.post-list {
  .post-item {
    border: 1px solid var(--ion-color-light-shade);
    margin: 0 0 12px;
    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    .post-preview-thumbnail {
      height: 25vw;
      width: 25vw;
      background-position: center;
      background-size: cover;
      margin: 0 5vw 0 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .comments-button {
        background: rgba(0, 0, 0, .8);
        margin: 0 auto;
        width: 65%;
        height: 65%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        border-radius: 50px;
        p {
          margin: 0;
          color: white;
          font-size: 2.5vw;
          text-align: center;
        }
      }
    }
    .post-preview-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .post-status-line {
        font-size: 3vw;
        margin: 2vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          margin: 0;
          display: flex;
          strong {
            color: var(--ion-color-primary);
          }
          .instagram-logo {
            width: 1.25em;
            margin: 0 1vw;
          }
        }
        ion-icon {
          font-size: 6vw;
          margin-top: -1vw;
          cursor: pointer;
        }
        .post-link {
          color: var(--ion-color-medium-shade);
          font-weight: normal;
          span {
            font-weight: bold;
            text-transform: capitalize;
            &.referral,
            &.news {
              color: var(--ion-color-primary-shade);
            }
            &.service {
              color: var(--ion-color-success-shade);
            }
            &.product {
              color: var(--ion-color-secondary-shade);
            }
          }
        }
      }
      .post-statistics {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .post-statistic {
          h4 {
            font-size: 3.5vw;
          }
          h3 {
            margin: 2vw 0;
            font-size: 6.875vw;
            text-align: center;
            color: var(--ion-color-primary-shade);
          }
        }
      }
    }
  }
}
.post-list-action-sheet-options {

  .action-sheet-group {
    .action-sheet-button {
      .action-sheet-button-inner {
        color: var(--ion-color-medium);
      }
      &.action-sheet-delete .action-sheet-button-inner {
        color: var(--ion-color-danger);
      }
    }
  }
}

@import "respomsive";
