.feed-tabs {
  @media screen and (min-width: 600px) {
    position: relative;
    .feed-tabs-bar {
      padding: 12px 0 0;
      overflow: hidden;
      ion-tab-button {
        position: relative;
        font-size: 1.2rem;
        hr {
          margin: 6px 60px 0;
          height: 6px;
        }
        &.traverse {
          display: block;
          position: absolute;
          padding: 0;
          opacity: 0;
          z-index: 0;
          transition: opacity .3s ease-in-out, z-index .1s linear;
          &.visible {
            opacity: 1;
            z-index: 100;
          }
          &.forward {
            right: 0;
          }
          &.backward {
            left: 0;
          }
        }
      }
    }
    .tabs-inner ion-router-outlet {
      .news-feed-post, .no-posts-wrapper {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.introduction-modal {
  @media screen and (min-width: 400px) {
    --width: 360px;
    padding-bottom: 40px;
  }
}
