.plt-mobileweb,
.plt-desktop {
  .accept-invitation-page {
    .not-logged-in {
      text-align: center;
      p {
        margin: 30px auto 60px;
        max-width: 300px;
      }
      ion-button {
        margin: 10px auto;
      }
    }
  }
  .invitation-accepted {
    .side-by-side {
      max-width: 480px;
      margin: 0 auto;
      img {
        margin-bottom: 2vw;
      }
    }
    ion-button {
      margin-bottom: 0;
    }
  }
}
