
.location-details{
  display: flex;
  align-items: center;
  .profile-image{
    width: 10vw;
    height: 10vw;
    .image-viewer{
      border-color: var(--ion-color-contrast);
      border-width: 1px;
    }
  }
  p{
    padding: 0;
    font-weight: normal;
  }
}
