.subscription-form {
  ion-slide {
    flex-direction: column;
    overflow: hidden;
    .payment-method-slide {
      width: 100%;
      text-align: left;
      h4 {
        margin: 1vw auto 3vw;
      }
    }
    .free-plan-confirmation-slide {
      .confirmation-page-content-details {
        padding: 5vw;
        text-align: left;
        h4 {
          color: var(--ion-color-medium);
          margin: 3vw auto;
        }
        p {
          margin: 3vw auto;
        }
        li {
          font-size: 3.5vw;
        }
        hr {
          margin: 10vw auto 5vw;
        }
      }
    }
  }
}
