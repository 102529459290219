.confirmation-page-content-details {
  .payment-method-info {
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 1px;
    padding: 5vw;
    p {
      margin: 0 0 2vw;
    }
    .card-number {
      font-weight: bold;
      font-size: 8vw;
      display: flex;
      line-height: 5vw;
      color: var(--ion-color-dark);
      .last-4 {
        font-size: 4vw;
        color: black;
        margin: 1px 0 0 2vw;
        align-self: center;
      }
    }
  }
}
