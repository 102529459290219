.logged-in-header {
    overflow: visible;
    contain: none;
    &.orange-header{
        ion-toolbar{
            --background:var(--ion-color-primary);
            ion-back-button {
                --color:white;
            }
            ion-title{
                color:white;
            }
            ion-buttons{
                ion-icon{
                    color:white;
                }
            }
        }
        ion-buttons {
            padding: 5vw 4vw 0;
        }
    }
    &.black-header{
        padding-top: 15px;
        ion-title{
            color: var(--ion-color-dark);
            text-align: left;
            font-size: 1.8rem;
            padding-inline-start: 30px;
        }
    }
    ion-toolbar {
        --color: var(--ion-color-primary);
        --background: white;
        border-bottom: none;
        overflow: visible;

        ion-back-button {
            font-size: 16px;
            width: 32px;
            height: 32px;
            --color: var(--ion-color-medium);
            display: block;
            visibility: visible;
        }

        ion-title {
            display: flex;
            text-align: center;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        ion-buttons {
            min-width: 76px;
            padding: 0 16px;
            position: relative;
            overflow: visible;
            ion-icon {
                width: 32px;
                height: 32px;
                color: var(--ion-color-medium);
                cursor: pointer;
            }
            .right-button {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
    .ellipsis-menu {
        position: absolute;
        top: 100%;
        z-index: 999;
        left: 40%;
        right: 5%;
        border: 2px solid var(--ion-color-medium-shade);
        border-radius: 20px;
        ion-item {
            --padding-start: 0;
            ion-label {
                padding-left: 20px;
            }
        }
    }
}
.plt-android {
    .logged-in-header {
        ion-buttons {
            width: 18vw;
            height: 18vw;
        }
    }
}
@import "responsive";
