.news-feed-post {
  padding-bottom: 2vw;
  padding-top: 130px;
  ~ .news-feed-post {
    padding-top: 0;
  }
}
ion-content.md, .plt-mobileweb {
  .news-feed-post {
    padding-top: 80px;

    ~ .news-feed-post {
      padding-top: 0;
    }
  }
}
@import "responsive";
