.feed-page {
  .options-button {
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 50px;
    font-size: 24px;
    color: var(--ion-color-medium);
    padding: 5px;
  }
  ion-header {
    padding-bottom: 0 !important;
    ion-toolbar {
      margin: 0 auto;
      &.md {
        padding-top: 10px;
        height: 50px;
      }
      box-sizing: content-box;
      padding-bottom: 0 !important;
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      ion-icon {
        border: 1px solid var(--ion-color-light-shade);
        border-radius: 50px;
      }
    }
  }
  &.settings-hidden {
    .feed-tabs {
      .feed-tabs-bar {
        top: var(--ion-safe-area-top);
      }
    }
  }
}

.feed-tabs {
  background: white;
  .feed-tabs-bar {
    top: calc(80px + var(--ion-safe-area-top));
    position: relative;
    padding: 0 0 0 20px;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    background: white;
    border: none;
    overflow: scroll;
    border-bottom: 1px solid var(--ion-color-light-shade);
    transition: top .2s linear;
    ion-tab-button {
      color: var(--ion-color-medium);
      font-size: 4vw;
      text-align: center;
      padding: 0 8px 0 0;
      font-weight: normal;
      flex-grow: 0;
      white-space: nowrap;

      ion-icon {
        font-size: 24px;
        color: var(--ion-color-medium);
        padding: 5px 5px 5px 0;
      }
      &.tab-selected {
        font-weight: bold;
      }
        &.traverse {
            display: none;
        }
    }
  }
  .tabs-inner ion-router-outlet {
    padding: 80px 0 0;
    .ion-page {
      width: 100%;
      height: 100%;
      > * {
        border-radius: 20px;
      }
      .permission-prompt {
        --padding-top: 120px;
      }
      ion-refresher {
        margin-top: 130px;
        &.md {
          margin-top: 80px;

        }
      }
    }
  }
}

.introduction-modal {
  --width: 90vw;
  padding-bottom: 10vw;
  --border-radius: 25px;
  --height: fit-content;
  align-items: flex-end;
  .introduction-modal-content {
    padding: 25px;
    text-align: center;
    .profile-name {
      background: rgb(131, 90, 246);
      width: 80px;
      height: 80px;
      margin: 0 auto 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      color: white;
      font-weight: bold;
      font-size: 32px;
    }
    ion-button {
      display: block;
      width: 50%;
      margin: 25px auto 0 ;
      --border-radius: 25px;
    }
  }
}
@import "responsive";
