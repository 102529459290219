@media screen and (min-width: 600px) {
  .notification-permission-content {
    ion-icon {
      width: 60px;
      height: 60px;
    }
    p {
      font-size: 48px;
      margin: 24px 0 48px;
    }
  }
  #welcome-section .notification-permission-content {
    p {
      font-size: 48px;
    }
  }
}
