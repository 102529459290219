#organization-menu {
    --padding-top: var(--ion-safe-area-top);
}
.organization-menu-content {
    ion-button {
        --border-radius: 0;
        width: auto;
        max-width: 50vw;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    h4 {
        font-size: 5.5vw;
        margin-top: 4vw;
        &.account-admin-header {
            margin-top: 8vw;
            margin-bottom: 6vw;
        }
    }
    hr {
        background: var(--ion-color-light-shade);
        height: 1px;
    }
    ion-item {
        --padding-start: 5px;
        font-size: 4vw;
        ion-icon {
            margin-right: 12px;
        }
    }
    .account-switcher {
        color: var(--ion-color-primary);
        font-weight: bold;
    }
    .instagram-logo {
        width: 1.35em;
        margin-right: 12px;
    }
}

@import "responsive";
