.subscription-upgraded-page {
  ion-header {
    ion-toolbar {
      --border-width: 0 !important;
    }
    ion-button {
      width: auto;
      margin: 2vw 5vw;
    }
  }
  ion-content {
    text-align: center;
    h3 {
      color: var(--ion-color-medium);
      margin: 10vw auto;
    }
    p {
      margin: 15vw auto;
    }
    ion-icon {
      width: 45vw;
      height: 45vw;
      margin: 5vw auto 20vw;
    }
  }
}
