.schedule-section {
  border-bottom: 1px solid var(--ion-color-primary);
  padding: 0 0 5vw;
  .schedule-title {
    color: var(--ion-color-primary);
    position: relative;
    span {
      background: white;
      display: block;
      text-align: center;
      width: fit-content;
      padding: 0 1.5625vw;
      font-size: 4vw;
    }
    hr {
      background: var(--ion-color-primary);
      display: block;
      height: 1px;
      margin: 0;
      position: absolute;
      bottom: 5px;
      z-index: -1;
      left: 0;
      right: 0;
    }
  }
  ion-item {
    cursor: pointer;
    --padding-start: 12px;
    font-size: 3.5vw;
    span {
      width: 60%;
      text-align: center;
      background: var(--ion-color-light);
      padding: 5px 0;
      .date {
        color: var(--ion-color-secondary);
        margin: 0 5px;
        min-width: 75%;
        display: inline-block;
      }
    }
  }
}
.schedule-date-modal {
  ion-datetime {
    --background: white;
   }
  ion-button {
    margin: 10px;
    width: 25%;
    align-self: flex-end;
  }
}
:host .datetime-calendar, :host .datetime-year {
  display: none !important;
}
@import "responsive";
