.post-details-content-wrapper {
  border-radius: 20px;
  overflow: hidden;
  perspective: 1px;
}

.public-post-page{
  .main-content {
    --padding-top: 0;
  }
  .logged-in-header{
    ion-toolbar {
      --color: var(--ion-color-secondary);
      ion-buttons {
        margin: 0 5vw;
      }
    }
  }
}
