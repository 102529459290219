.entity-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .entity-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .entity-details-text {
      text-align: left;
      padding-left: 3vw;
      flex: 0;
      flex-grow: 1;
      h6 {
        font-weight: bold;
        margin:0;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
  .profile-image {
    width: 12vw;
    height: 12vw;
  }
  ion-button {
    width: 21vw;
    height: 8vw;
    font-size: 3vw;
    --border-radius: 5px;
    &.button-outline {
      --border-radius: 2px;
    }
  }
}

@import "responsive";
