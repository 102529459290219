.topics-list{
	width: 100%;
	.topic-item{
		font-weight: bold;
		margin: 20px 0;
	}
	.four-square{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 32px;
		height: 32px;
		margin-right: 10px;
		& > div {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			width: 32px;
			height: 15px;
			&:before, &:after{
				content: ' ';
				display: block;
				border-top: 15px solid darkgray;
				width: 15px;
			}
		}
	}
}

@import "responsive";
