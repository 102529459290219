.organization-header {
    background: white;
    ion-toolbar {
        max-width: 960px;
        margin: 0 auto;
        --color: var(--ion-color-primary);
        border-bottom: 1px var(--ion-color-light) solid;

        ion-button,
        ion-menu-button,
        ion-back-button {
            min-width: 32px;
            color: var(--ion-color-primary);
            display: block;
            visibility: visible;
            &.menu-button-hidden {
                visibility: hidden;
            }
        }
        ion-button {
            margin: 0;
            padding: 0 10px;
        }
        .create-button {
            color: var(--ion-color-medium)
        }

        ion-title {
            font-size: 4.375vw;
            padding-left: 15vw;
            padding-right: 15vw;
            text-transform: capitalize;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .barlow {
                font-size: 32px;
            }
        }
    }
}

@import "responsive";
