
.location-creator-page {
  .manual-entry-helper {
    @media screen and (min-width: 640px) {
      margin: 32px 0;
    }
    @media screen and (min-width: 600px) {
      p {
        margin: 12px 0;
      }
    }
  }
  ion-slide > .side-by-side {
    > ion-icon {

      @media screen and (min-width: 640px) {
        font-size: 32px;
      }
    }
    > div {

      @media screen and (min-width: 800px) {
        width: 560px;
        margin-right: 32px;
      }
    }
    .hide-address-line {
      @media screen and (min-width: 600px) {
        p {
          font-size: 18px;
        }
      }
    }
  }
}
