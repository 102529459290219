.lds-ripple {

    visibility: hidden;
    position: relative;
    width: 35px;
    height: 35px;
    div {
        position: absolute;
        opacity: 1;
        border: 2px solid #000;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
    &.visible {
        visibility: visible;
    }
}
@keyframes lds-ripple {
    0% {
        top: 18px;
        left: 18px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        opacity: 0;
    }
}
