#account-page {
  ion-content{
    --padding-top: 0;
  }
  .account-header{
    width: 100%;
    .account-stripe{
      background-color: var(--ion-color-primary);
      height: 12.5vw;
      width: 100%;
      left: 0;
      z-index: 2;
    }
    .profile-image {
      left: 0;
      height: 24vw;
      width: 24vw;
      margin: -12vw auto 0;
      z-index: 2;
      &.is-scrolled{
        height: 16vw;
        width: 16vw;
      }
    }
  }
  .settings-icon{
    float: right;
    font-size: 7vw;
    padding: 0;
    margin-right: 4vw;
  }
  .invite-friends {
    margin-top: 5vw;
    border: solid 1px var(--ion-color-primary);
    color: var(--ion-color-primary)
  }
  .side-by-side {
    display: flex;
    padding-top: 30vw;
    padding-bottom: 0;
    justify-content: center;

    .following-totals {
      text-align: center;
      flex-grow: 0;
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      p {
        margin: 0;
        font-weight: bold;
      }
    }
  }
  #add-bio-link {
    margin-top: 5vw;
    margin-bottom: 5vw;

    a {
      color: var(--ion-color-secondary)
    }
  }
  .user-invite{
    bottom: 0;
    width: 100%;
    background-color: white;
    .user-invite-friends-bar{
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
      padding: 3vw 6vw;
      border-top: 5px solid var(--ion-color-light);
      font-weight: bold;
      ion-button{
        max-width: 50vw;
        font-weight: bold;
        font-size: 1.2em;
      }
    }
  }
  .posts-swiper{
    padding-bottom: 20vw;
  }
  .accepted-link{
    color: var(--ion-color-base);
  }
}

@import "responsive";
