#profile-page {
  .grey-background-section {
    margin: 18.75vw 6.25vw 0;
    text-align: center;
    position: relative;
    .profile-image {
      width: 37.5vw;
      height: 37.5vw;
      margin: -21.875vw auto 3.125vw;
    }

    ion-router-link {
      position: absolute;
      color: var(--ion-color-medium);
      right: 3.125vw;
      top: 3.125vw;
      font-size: 6.25vw;
    }

    .contact-info {
      p {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon {
          font-size: 6vw;
        }
      }
    }

    .demographics-details {
      margin: 4.6875vw 0;
      > div {
        display: flex;
        > p {
          width: 50%;
          margin: 0 5px;
          &:first-child {
            text-align: right;
          }
          &:last-child {
            text-align: left;
          }
        }
      }
    }
  }
}

@import "responsive";
