.post-published-page {

  ion-content {
    @media screen and (min-width: 480px) {
      h2 {
        font-size: 48px !important;
      }

      ion-icon {

        width: 160px !important;
        height: 160px !important;
        margin: 72px auto 48px !important;
      }
    }
  }
}
