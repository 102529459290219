.gray-input {
  ion-datetime,
  ion-input {
    --padding-start: 3.125vw;
    border: transparent;
  	background: var(--ion-color-light);
  	border-radius: 10px;
  }
}
@import "responsive";
