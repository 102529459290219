.user-page {
  .user-content {
    .user-info {
      margin: 0 0 5vw;
      .user-profile-image-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .profile-image {
          width: 20vw;
          height: 20vw;
        }
        span,
        ion-button {
          width: 30%;
          display: block;
        }
      }
      .followers-statistics {
        display: flex;
        margin: 10vw 0;
        justify-content: flex-start;
        .followers-statistic {
          margin: 0 2vw 0 0;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
