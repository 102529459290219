#welcome-section {
  h1, h2, h3 {
    text-align: center;
    color: var(--ion-color-primary-shade);
  }
  h1 {
    margin: 0;
    font-size: 7vw;
  }
  h2 {
    font-size: 3.75vw;
  }
  h3 {
    font-size: 9vw;
  }
  p {
    font-size: 4vw;
  }
  ion-router-link {
    font-weight: bold;
  }
  ion-button {
    --padding-top: 0;
    margin: 3vw 0;
  }
}
@import "responsive";
