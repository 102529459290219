.current-subscription-page {
  .main-wrapper {
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-top: 0;
  }


  .active-subscription-details {
    .plan-details-header {
      margin: 8vw 0 5vw;
    }
    .plan-details {
      p {
        font-size: 4vw;
        margin: 2vw 0;
        &:last-child{
          margin: 2vw 0 7vw;
        }
        strong {
          margin-right: 1vw;
        }
      }
    }
    .upcoming-renewal-estimate {
      background: var(--ion-color-light-shade);
      padding: 4vw;
      h4 {
        font-weight: normal;
        margin: 0;
        font-size: 5vw;
      }
      h3 {
        color: var(--ion-color-dark);
        font-weight: normal;
        text-align: left;
        margin: 0;
        font-size: 8vw;
      }
      p {
        font-size: 3.5vw;
        margin: 3vw 0;
      }
      ion-button {
        --border-radius: 0;
        max-width: 40vw;
      }
    }
    .upcoming-renewal-line-items {
      padding: 0 4vw;
      p {
        font-size: 4vw;
        .amount {
          float: right;
        }
      }
      hr {
        background: var(--ion-color-light-shade);
        height: .5vw;
        margin: 0 2vw;
      }
    }
  }
}
