.posts-swiper{
    .swiper-navigation{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100vw;
        left: calc(-1*var(--padding-start));
        padding: 0 10vw;
        border-bottom: 1px solid #eeefef;
        h6{
            margin-bottom: 0;
            button{
                font-size: .8em;
                font-weight:bold;
                color:var(--ion-color-base);
                background-color: transparent;
                padding-bottom: 8px;
                border-bottom: 5px solid transparent;
                &.swiper-button-disabled{
                    border-bottom: 5px solid var(--ion-color-primary);
                }
            }
            &:only-child{
                button{
                    border-bottom: 5px solid var(--ion-color-primary);
                }
            }
        }
    }
    .swiper{
        .swiper-slide{
            align-items: flex-start;
            padding: 0;
            .saved-posts-content-list{
                padding-top: 4vw;
            }
        }
    }
}

@import "responsive";
