#welcome-section {

  @media screen and (min-width: 600px) {
    p {
      font-size: 24px;
    }
    h3 {
      font-size: 54px;
    }
    ion-button {
      margin: 18px 0;
    }
  }
  @media screen and (min-width: 685px) {
    h1 {
      font-size: 48px;
    }
  }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 36px;
    }
  }
}
