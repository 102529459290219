.input-wrapper {
  @media screen and (min-width: 600px) {
    margin: 48px 0 30px;
  }
  ion-input {
    @media screen and (min-width: 576px) {
      --padding-start: 18px;
    }
  }
}
.plt-android {
  .input-wrapper {
    ion-label {
      @media screen and (min-width: 600px) {
        padding-bottom: 21px;
      }
    }
  }
}
