@media screen and (min-width: 600px) {
  #followers-page {
    ion-item {
      margin: 30px 0;
      p {
        font-size: 24px;
      }
      .profile-image {
        width: 72px;
        height: 72px;
      }
      .entity-details-text{
        padding-left: 20px;
      }
      ion-button {
        width: 120px;
        height: 54px;
        button {
          font-size: 24px;
        }
      }
    }
    #no-followers {
      --padding-top: 90px;
      --padding-start: 42px;
      --padding-end: 42px;
      ion-icon {
        margin: 60px 0 30px;
        width: 240px;
        height: 240px;
      }
      h4 {
        font-size: 30px;
      }
      p {
        font-size: 24px;
      }
    }
  }

}
