.location-search {
    &.input {
        .side-by-side {
            ion-icon {
                width: 5%;
                height: 12vw;
                max-height: 50px;
                color: var(--ion-color-medium);
                margin-right: 2vw;
            }

            .search-input-wrapper {
                width: 85%;
                text-align: left;

                .search-input {
                    position: relative;

                    ion-input {
                        border-bottom: 2px solid var(--ion-color-dark);
                        margin: 0 5px 0 0;
                        width: auto;
                        --padding-start: 10px;
                    }

                    ion-list {
                        position: absolute;
                        top: 100%;
                        left: 5px;
                        right: 5px;
                        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, .5), -2px 2px 5px 0px rgba(0, 0, 0, .5);;
                        ion-item {
                            cursor: pointer;
                        }
                    }
                }

                p {
                    margin: 0;
                    padding-left: 10px;
                    color: var(--ion-color-dark);
                }
            }
        }
    }

    .location-details-wrapper {
        margin: 0 0 20px;
    }
}
