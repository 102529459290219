.upcoming-activity {
  .no-posts {
    text-align: center;
    padding: 5vw;
    color: var(--ion-color-medium);
    h4 {
      font-size: 4.75vw;
      color: var(--ion-color-medium);
    }
    img {
      margin: 6vw auto 0;
    }
    p {
      font-size: 4vw;
    }
    ion-button {
      --border-radius: 0;
      margin: 3vw auto;
      max-width: 60vw;
    }
  }
}
