.post-response-page{
  ion-item {
    @media screen and (min-width: 600px) {
      padding: 0 0 36px;
    }
    p {
      @media screen and (min-width: 640px) {
        font-size: 24px;
      }
    }
    ion-button {
      @media screen and (min-width: 600px) {
        width: 120px;
      }
      @media screen and (min-width: 640px) {
        height: 60px;
        button {
          font-size: 26px;
        }
      }
    }
  }
  .no-interaction{
    @media screen and (min-width: 600px) {
      padding: 48px 39px;
      ion-icon {
        font-size: 300px;
        padding: 36px 0;
      }
    }
  }
}
