.post-item-with-business {
  display: flex;
  --padding-top: 2.5vw;
  --padding-bottom: 2.5vw;
  --inner-padding-end: 0;
  --padding-start: 0;
  cursor: pointer;
  border-bottom: 1px solid lighten(black,75%);
  .post-item-details {
    display:flex;
    max-width: 100%;
    border-radius: 0;
    align-items: center;
    p {
      margin: 0;
      padding-left: 9vw;
      font-size: .8rem;
      strong{
        font-size: 1.1rem;
      }
    }
    .post-location-details{
      display: flex;
      align-items: center;
      p {
        padding-left: 2vw;
        margin: 0;
        strong {
          font-size: .8rem;
        }
      }
    }
    .profile-image {
      display: inline-block;
      width: 7vw;
      height: 7vw;
      border: 1px solid var(--ion-color-primary);
      border-radius: 50%;
    }
    .post-content {
      flex-basis: 68.5%;
      padding: 0 2vw 0 0;
      & > p:only-child{
        padding-left: 0;
      }
    }
    .post-preview-thumbnail {
      height: 27vw;
      width: 31.5%;
      flex-basis: 31.5%;
      border-radius: 8px;
      background-position: center;
      background-size: cover;
    }
  }
}
@import "responsive";
