.text-input-with-label-in-border {
  ion-input {
    --padding-start: 2vw;
    --paddint-top: 0;
    text-align: start;
  }
  ion-textarea {
    --padding-start: 2vw;
    --padding-bottom: 5vw;
    --paddint-top: 0;
    text-align: start;
    &.multiline {
      min-height: 89px;
    }
  }
}
