.profile-image {
  @media screen and (min-width: 480px) {
    width: 72px;
    height: 72px;
    &.is-scrolled{
      width: 48px;
      height: 48px;
    }
  }
}
