.sign-in-phone-page {
  h4 {
    margin: 16px 0;
  }
  ion-item {
    border: 2px var(--ion-color-primary) solid;
    --padding-start: 9px;
    margin: 0 0 32px;
    ion-input {
      --placeholder-font-weight: bold;
      --placeholder-color: var(--ion-color-primary-shade);
      --placeholder-opacity: 1;
    }
  }
  a {
    display: block;
    font-weight: bold;
    color: var(--ion-color-secondary);
    margin: 32px 0 40px;
    padding-left: 1.875vw;
  }
  ion-button.button-disabled {
    --background: var(--ion-color-medium-tint);
  }
}
