#home .user-page {
  .user-header{
    @media screen and (min-width: 480px) {
      .user-profile-image-line {
        .profile-image {
          height: 120px;
          width: 120px;
        }
      }
    }
    @media screen and (min-width: 600px) {
      .user-stripe {
        height: 24px;
      }
      .user-profile-image-line {
        margin-top: -24px;
        .profile-image {
          &.is-scrolled{
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
  .user-content {
    @media screen and (min-width: 600px) {
      padding-top: 42px;
      .follow-button-wrapper{
        padding-bottom: 42px;
      }
      .user-info {
        margin: 12px 0;
        .followers-statistics {
          margin: 18px 0 0;
          .followers-statistic {
            margin: 0 32px 0 0;
            strong{
              padding-right: 6px;
            }
          }
        }
      }
      .referrals {
        h6 {
          padding-bottom: 12px;
        }
      }
    }
  }
}
