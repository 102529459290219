.profile-image {
  width: 15vw;
  height: 15vw;
  position: relative;
  transition: height .3s ease-in-out, width .3s ease-in-out;
  &.is-scrolled{
    width: 15vw;
    height: 15vw;
  }
  ion-icon.image-viewer {
    color: var(--ion-color-medium);
  }
  .image-viewer {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    background-position: center;
    background-size: cover;
    background-color: white;
    color: white;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 180%;

    &.gradiant-a,
    &.gradiant-s,
    &.gradiant-d,
    &.gradiant-f,
    &.gradiant-g,
    &.gradiant-h {
      background-image: linear-gradient(90deg, #77aa44, #993399);
    }
    &.gradiant-j,
    &.gradiant-k,
    &.gradiant-l,
    &.gradiant-z,
    &.gradiant-x,
    &.gradiant-c {
      background-image: linear-gradient(90deg, #3399ee, #337733);
    }
    &.gradiant-v,
    &.gradiant-b,
    &.gradiant-n,
    &.gradiant-m,
    &.gradiant-q,
    &.gradiant-w {
      background-image: linear-gradient(90deg, #aaaa88, #66aaee);
    }
    &.gradiant-e,
    &.gradiant-r,
    &.gradiant-t,
    &.gradiant-y,
    &.gradiant-u,
    &.gradiant-i {
      background-image: linear-gradient(90deg, #995599, #88eeee);
    }
    &.gradiant-o,
    &.gradiant-p,
    &.gradiant- {
      background-image: linear-gradient(90deg, #cc4444, #4444cc);
    }
  }
  div.image-viewer {
    border: var(--ion-color-primary) 2px solid;
  }
  .plus-sign {
    position: absolute;
    color: var(--ion-color-secondary);
    right: 3%;
    bottom: 3%;
    width: 25%;
    height: 25%;
    background: white;
    border-radius: 50px;
  }
  &.no-border {
    div.image-viewer {
      border: none;
    }
  }
  &.white-border {
    div.image-viewer {
      border-color: white;
    }
  }
}

@import "responsive";
