.business-header {
  .featured-images {
    height: 33vw;
  }
  .business-details {
    padding: 4.6875vw 0 0;
    border-bottom: 5px solid var(--ion-color-light-shade);
    .business-info-line {
      padding-bottom: 4.6875vw;
    }
    .edit-business-line {
      margin: 0 15px;
      padding-bottom: 1.6875vw;
      text-align: right;
      cursor: pointer;
    }
    h5, p {
      padding-left: 15px;
      margin: 0;
    }
  }
}

@import "responsive"
