.post-response-page {
  @media screen and (min-width: 400px) {
    ion-item {
      padding: 0 0 40px;
    }
  }

  .no-interaction{
    padding: 8vw 6.5vw;
    ion-icon{
      padding: 6vw 0;
    }
  }
}
