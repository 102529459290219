.sign-up-page {
  ion-content {
    --padding-top: 0;
    .flex-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      .sign-up-disclaimer-wrapper {
        flex-grow: 1;
        align-items: flex-end;

        .sign-up-disclaimer {
          display: flex;
          clear: both;
          margin-bottom: var(--ion-safe-area-bottom);
          ion-icon {
            font-size: 10vw;
            width: 25vw;
            margin: 0 2.5vw 0 0;
            display: block;
            flex-grow: 1;
          }
          p {
            margin: 0;
          }
        }
        .age-notice {
          text-align: center;
        }
      }
    }
  }
}
@import "desktop";
