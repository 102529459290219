.location-creator-page {
  .manual-entry-helper {
    text-align: left;
    margin: 5vw 0;
    p {
      margin: 2vw 0;
    }
    a {
      color: var(--ion-color-secondary);
      font-weight: normal;
    }
  }
  ion-slide > .side-by-side {
    text-align: left;
    > ion-icon {
      width: 10%;
      font-size: 5vw;
      color: var(--ion-color-medium);
      margin-top: 5px;
    }
    > div {
      width: 70vw;
      margin-right: 5vw;
    }
    .side-by-side {
      padding: 5px 0;
      div:first-child {
        width: 35%;
        margin: 0 5% 0 0;
      }
      div:last-child {
        width: 60%;
      }
    }
    .hide-address-line {
      ion-item {
        --padding-start: 0;
        ion-checkbox {
          margin: 0 6px 0 0;
        }
      }
      p {
        margin: 0;
        font-size: 3vw;
      }
    }
  }
}
@import "responsive";
