.forgot-password-page {

  p {
    text-align: center;
    margin-left: 6.25vw;
    margin-right: 6.25vw;
  }
  .complete {
    padding-top: 9.375vw;
    p {
      margin: 6.25vw 7.8125vw 12.5vw;
    }
  }
}
