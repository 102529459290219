.browse-page {
  ion-header {
    padding-top: 3vw;
  }
  ion-content {
    --padding-top: 0;
    ion-searchbar {
      padding-left: 5vw;
      padding-right: 5vw;
    }
    ion-item {
      margin: 5vw 0;
      --padding-start: 0;
      --padding-end: 0;
      --background-activated: transparent;
    }
    .results-wrapper {
      --padding-bottom: 18vw;
    }
  }
  .no-results,
  .no-locations {
    padding: 8vw 6.5vw;
    text-align: center;
    color: var(--ion-color-medium);
    h4{
      color: var(--ion-color-medium);
    }
    ion-icon{
      font-size: 50vw;
      padding: 6vw 0;
    }
    .subhead{
      font-weight: bold;
    }
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vw;
    ion-icon {
      color: var(--ion-color-medium-tint);
      padding: 0;
      margin: 0
    }
  }
  .default-search-results {
    hr {
      height: 1px;
      background: var(--ion-color-primary);
    }
  }
  .results-section {
    h2 {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 5vw;
    }
    h3 {
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5vw;
      ion-icon {
        margin: 3px 3px 0;
      }
    }
  }
}
@import "responsive";
