#profile-page {
  .grey-background-section {
    @media screen and (min-width: 533px) {
      margin: 100px 6.25vw 0;
    }
    @media screen and (min-width: 576px) {
      margin: 100px 36px 0;
    }
    .profile-image {
      @media screen and (min-width: 548px) {
        margin: -120px auto 3.125vw;
      }
      @media screen and (min-width: 576px) {
        margin: -120px auto 18px;
      }
      @media screen and (min-width: 600px) {
        width: 225px;
        height: 225px;
      }
    }

    ion-router-link {
      @media screen and (min-width: 600px) {
        right: 20px;
        top: 20px;
        font-size: 40px;
      }
    }

    .contact-info {
      p {
        ion-icon {
          @media screen and (min-width: 600px) {
            font-size: 36px;
          }
        }
      }
    }

    .demographics-details {
      @media screen and (min-width: 640px) {
        margin: 30px 0;
      }
    }
  }
}
