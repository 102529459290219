.bottom-sticky-section {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  .side-by-side {
    padding: 2vw;
    margin: 0;
  }
  ion-button {
    margin: 0;
  }
}
