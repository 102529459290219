.payment-method-option{
  --padding-start: 0;
  --background-activated: #fff;
  display: flex;
  align-items: center;
  ion-radio{
    margin-right: 2vw;
  }
  .payment-method-item {
    width: 100%;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    div {
      padding: 2vw;
    }
    border: 2px var(--ion-color-medium) solid;
    border-radius: 2px;
    .number {
      font-size: 4vw;
      border-bottom: 2px var(--ion-color-medium) solid;
      .remove-link {
        color: var(--ion-color-secondary);
        float: right;
      }
    }
    .default-method {
      float:right;
    }
  }
}