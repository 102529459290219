.main-category-selector {
  ion-content {
    --padding-start: 8vw;
    --padding-end: 8vw;
    --padding-top: 12vw;
    --padding-bottom: 55px;
  }
  h4{
    color: var(--ion-color-medium);
    padding-bottom: 8vw;
  }
  .bottom-sticky-section {
    display: block;
    max-width: 75vw;
    margin: 0 auto 2em;
  }
}
