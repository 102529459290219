.post-published-page {
  ion-toolbar, ion-content {
    --background: var(--welcome-background);
  }
  ion-toolbar {
    --border-width: 0 !important;
    ion-button {
      --background: none;
      --color: var(--ion-color-dark);
      margin: 0 0 0 3vw;
      font-size: 6vw;
    }
  }
  ion-content {
    text-align: center;
    h2 {
      color: var(--ion-color-primary);
      font-weight: bold;
      font-size: 10vw;
    }
    ion-icon {
      width: 33vw;
      height: 33vw;
      color: var(--ion-color-primary);
      margin: 15vw auto 10vw;
    }
    p {
      padding: 0 3px;
    }
    ion-button {
      max-width: 60%;
      margin: 10vw auto;
    }
  }
}

@import "responsive";
