.post-published-details {
  --background: transparent;
  --padding-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .profile-image {
    height: 12vw;
    min-width: 12vw;
    width: 12vw;
    margin-right: 2vw;
  }
  p {
    margin: 0;
    color: white;
    flex-grow: 1;
    line-height: 1em;
    font-size: 3.5vw;
  }
  .follow-button-wrapper{
    margin-right: 0;
    margin-left: 10px;
    width: 21vw;
  }
}

@import "responsive";
