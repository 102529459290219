.album-post-thumbnail-placeholder, .album-post-thumbnail{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35vw;
  height: 35vw;
  background-color: darkgray;
  border-radius: 20px;
  border: 4px solid var(--ion-color-light-shade);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &.no-border{
    border: none;
  }
}

@import "responsive";
