.post-not-found {
  text-align: center;
  margin: 120px auto 0;
}
.post-wrapper {
  border-radius: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  &.user-post{
    .post-details{
      background-color: var(--ion-color-quaternary);
    }
    .profile-image{
      margin-right: 10px;
      .image-viewer{
        border-width: 2px;
      }
    }
    ion-item.post-published-details{
      --ion-safe-area-right: 0;
    }
  }
  .mouse-actions {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 60px;
    justify-content: center;
    @media (pointer: fine) {
      display: flex;
    }
    .btn-post-action {
      margin: 0 5px;
      cursor: pointer;
      width: 80px;
      height: 80px;
      background: white;
      border: 5px solid var(--ion-color-primary);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .tooltip {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
      }
      &:hover .tooltip {
        visibility: visible;
        opacity: 1;

      }
      ion-icon {
        font-size: 30px;
      }
      &.btn-dismiss ion-icon {
        color: var(--ion-color-tertiary);
      }
      &.btn-save ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }
}

.post-details {
  background: black;
  color: white;
  padding: 15px;
  min-height: 100%;
  border-radius: 15px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .content-wrapper {
    border-radius: 25px;
    overflow: hidden;
    .main-post-image {
      display: block;
      width: 100%;
      height: 100%;
    }

    .post-video {
      max-width: 100%;
      border-radius: 25px;
    }
  }
}

@import "responsive";
