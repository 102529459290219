.label-in-border {
  .label {
    background: white;
    color: var(--ion-color-primary);
    margin: 0 0 -2.5vw 1vw;
    display: block;
    font-size: 4vw;
    padding: 0 1vw;
    position: relative;
    float: left;
  }
  .container {
    clear: both;
    border: 1px solid var(--ion-color-primary);
  }
  .char-count {
    color: var(--ion-color-dark);
    font-size: 4.5vw;
    margin: 1vw 0 2vw;
    text-align: right;
    &.too-long {
      color: red;
    }
  }
}

@import "responsive";
