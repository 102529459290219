.user-editor-page {
  ion-radio-group {
    ion-item {
      --padding-start: 0;
      p {
        height: auto;
        white-space: normal;
      }
    }
    &.md {
      ion-radio {
        margin: 5px;
      }
    }
    &.via-radio-group.md {
      display: flex;
      ion-col {
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        white-space: nowrap;
      }
    }
  }
  .bottom-sticky-section {
    padding: 5vw 10vw;
  }
}
