.post-editor-form {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 64px;
    ion-segment {
        --background: transparent;
        margin: 0 0 16px;
        ion-segment-button {
            --color-checked: var(--ion-color-primary);
            --border-color: none;
            --indicator-box-shadow: none;
            --padding-bottom: 10px;
            &::part(indicator) {
                margin: 3px 10px 0;
                border-bottom: var(--ion-color-primary) 5px solid;
            }
        }
    }

    .member-information-header {
        --padding-start: 0;
        --padding-end: 0;
        .profile-image {
            margin-right: 6px;
        }
        .member-information {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            min-height: 54px;
            align-items: center;
            .member-information-bubble {
                margin: 3px;
                padding: 2px 6px;
                white-space: nowrap;
                border: 2px var(--ion-color-primary) solid;
                border-radius: 6px;
                color: var(--ion-color-primary);
                font-size: 4vw;
                display: flex;
                align-items: center;
                ion-icon {
                    font-size: 5vw;
                }
            }
        }
    }

    .asset-uploader {
        height: 100vw;
        margin: 16px 0;
        text-align: center;
        background: var(--ion-color-primary-shade);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        ion-icon {
            color: white;
            border: 3px solid white;
            border-radius: 148px;
            padding: 10px;
            width: 11.25vw;
            height: 11.25vw;
        }
        p {
            font-size: 4vw;
            color: white;
        }
        .main-image-preview {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .member-post-location-selector {
        h3 {
            text-align: left;
        }
        ion-list ion-item {
            --padding-start: 0;
        }
        .side-by-side {
            padding: 0;
        }
        padding-bottom: 55vw;
        .search-input ion-list {
            overflow: scroll;
            height: 50vw;
        }
    }

    .label-in-border {
        margin: 2vw 0 1vw;
    }

    .toggle-line {
        margin: 1vw 0;
        --padding-start: 2vw;
        --padding-end: 2vw;
        color: var(--ion-color-secondary);

        ion-toggle {
            &.md {
                --handle-background: var(--ion-color-primary-light);
            }

            --background-checked: var(--ion-color-secondary);
            padding: 0;
        }
    }

    .button-section {
        margin: 4vw auto;

        .text-input-with-label-in-border {
            padding: 0 5vw;
        }

        .color-selector {
            padding: 0 0 5vw;
        }
    }

    .social-logo {
        width: 1.25em;
    }

    .grey-background-section {
        margin-bottom: 3vw;
        .side-by-side {
            padding: 0;
            ion-icon {
                width: 10vw;
                height: 10vw;
                margin-right: 5vw;
            }
            p {
                text-align: left;
                width: 75vw;
                margin: 0;
                font-size: 4.25vw;
            }
        }
    }

    .followers-check-line {
        display: flex;
        align-items: center;
        justify-content: center;
        ion-checkbox.md {
            margin-right: 3vw;
        }
        ion-item {
            --padding-start: 2vw;
            font-size: 5vw;
        }
        ion-icon {
            width: 10vw;
            height: 10vw;
            color: var(--ion-color-light-shade);
            cursor: pointer;
        }
    }
}

.bottom-sticky-section.submit-post-sticky-section {
    background: white;
    padding: 6px 12px;
    border-top: .1vw var(--ion-color-medium-shade) solid;
    ion-button {
        margin: 0 3px;
        &.bold{
            font-weight:bold;
        }
    }
}

@import "responsive";
