#bio-editor-page {
    .add-bio {
        padding: 0 6px;
        border: 1px solid var(--ion-color-primary);
    }
    ion-button {
        margin-top: 10vw;
        font-size: 6vw;
        padding-left: 4vw;
        padding-right: 4vw;
    }
}
@import "responsive";
