.save-to-album-modal {
  --border-radius: 20px;
  ion-header{
    ion-title{
      font-size: 1.2rem;
    }
    ion-icon{
      font-size: 1.8rem;
    }
  }
  ion-content {
    --overflow: scroll;
  }
  ion-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 20px;
    ion-button {
      width: 150px;
      justify-self: flex-end;
      font-weight: bold;
      font-size: 1.2rem;
      max-height: 50px;
      --border-radius: 5px;
      &.add-album {
        width: 100%;
      }
      &.clear {
        text-decoration: underline;
        --ion-color-base: var(--ion-color-communication) !important;
      }
    }
  }
}
