.news-feed-options-modal {
  ion-content {
    ion-range {
      --knob-background: var(--ion-color-primary);
      --bar-background-active: black;
      --bar-background: black;

      &::part(tick),
      &::part(tick-active) {
        height: 32px;
        background: black;
        top: 5px;
      }
    }

    .range-wrapper {
      text-align: center;

      .range-display {
        border: 1px solid var(--ion-color-medium);
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        margin: 0 auto;
        padding: 5px 30px;

        p {
          margin: 0;
        }
      }
    }

    hr {
      background: var(--ion-color-medium-tint);
      margin: 15px 0;

    }

    .filter-header {
      margin: 0 0 10px;
    }

    ion-item {
      --padding-start: 0;

      ion-label {
        display: flex !important;
        align-items: center;
      }

      ion-icon {
        font-size: 24px;
        padding: 0 10px;
      }
    }

    ion-checkbox::part(container) {
      border-radius: 6px;
    }
  }
  ion-footer {
    margin-bottom: 20vh;
    padding: 20px;
    border-top: 1px solid var(--ion-color-medium);
    text-align: right;
    ion-button {
      width: 150px;
    }
  }
}
