.no-subscription-wrapper {
  padding-top: 44px;
  height: 100%;
  width: 100%;
  p {
    font-size: 3vw;
  }

  .explanation-wrapper {
    border: 1px var(--ion-color-light-shade) solid;
    padding: 3vw;
    margin: 15vw 5vw 0;
    border-radius: 2px;
    h5 {
      font-weight: normal;
      color: var(--ion-color-medium);
      font-size: 5vw;
      margin: 4vw auto 10vw;
    }
    ion-item {
      --padding-start: 0;
      --padding-top:0;
      padding: 2vw;
      --background: transparent;
      border: 1px var(--ion-color-secondary) solid;
      border-radius: 2px;
      h6 {
        font-weight: bold;
        margin: 0;
      }
    }
  }
  .help-line {
    margin: 5vw 13vw;
    ion-router-link {
      color: var(--ion-color-secondary);
    }
  }
}
