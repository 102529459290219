#settings-page {
  ion-content.settings-menu {
    --padding-top: 10vw;
    h4 {
      background: var(--ion-color-light);
      padding: 3.125vw 4.6875vw;
    }

    ion-item {
      padding: 0 7.8125vw;
      font-size: 5vw;
    }
    .bottom-sticky-section {
      margin-bottom: 5vw;
      ion-button {
        padding-left: 5vw;
        padding-right: 5vw;
        --background: var(--ion-color-light);
        color: var(--ion-color-primary);
        font-size: 6vw;
      }
    }
  }
}
@import "responsive";
