@media screen and (min-width: 500px) {
  .home-splitpane {
    max-width: none;
    .home-tabs {
      padding: 1px;

      .profile-image {
        width: 40px;
        height: 40px;
      }

      ion-icon {
        font-size: 38px;
      }
    }
  }
}
