.album-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
  .album-list-item {
    flex-basis: 50%;
    padding-bottom: 15px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    overflow: visible;
    --inner-padding-end: 0;
    .album-item-content{
      margin: 0 auto;
      position: relative;
      overflow: visible;
      width: 35vw;
      p{
        font-weight: bold;
        font-size: 1rem;
        line-height: 1rem;
        margin: 10px 0 0;
      }
      ion-icon {
        position: absolute;
        top: -3%;
        left: -1%;
        font-size: 24px;
      }
    }
  }
}

@import "responsive";
