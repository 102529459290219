.subscription-editor {
  flex-direction: column;
  text-align: center;
  h5 {
    font-size: 3vw;
  }
  p {
    font-size: 2vw;
  }
  .subscription-intro{
    h3{
      margin: 0;
    }
    h5{
      font-size: 4vw;
    }
    p{
      margin: 1vw 0 3vw;
      font-size: 3vw;
    }
  }
  .tab-bar {
    display: flex;
    justify-content: space-evenly;
    margin: 5vw auto 2vw;
    > div {
      width: 25vw;
      text-align: center;
      padding: 1vw 2vw;
      font-weight: bold;
      color: var(--ion-color-medium);
      &.selected {
        padding: 1vw 2vw;
        border-bottom: .8vw solid var(--ion-color-primary);
      }

      ion-icon {
        color: var(--ion-color-primary);
        margin: 0 1vw 0 0;
      }
    }
  }

  .tab-content {
    border-radius: .8vw;
    border: .7vw solid var(--ion-color-primary);
    text-align: center;

    padding: 3vw 8vw 5vw;
    p {
      font-size: 3.5vw;
    }
    h4 {
      font-size: 6vw;
      margin: 3vw auto 0;
      color: var(--ion-color-medium);
      &.price-free {
        margin: 15vw auto 20vw;
        &.preview{
          margin-bottom: 15vw;
        }
      }
    }
    h5{
      font-size: 4.5vw;
    }

    ul {
      text-align: left;
      color: var(--ion-color-medium);
      font-size: 3vw;
      padding: 2vw 2vw 2vw 7vw;
      li {
        padding: 0;
        margin: 0;
      }
    }
    ion-button {
      --border-radius: 0;
    }

    .duration-line {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      ion-toggle {
        --background: transparent;
        --background-checked: transparent;
        border: .4vw solid var(--ion-color-primary);
        border-radius: 100px;
        --handle-box-shadow: none;
        --handle-background: var(--ion-color-primary);
        --handle-background-checked: var(--ion-color-primary);
        --handle-height: 5vw;
        --handle-width: 5vw;
        height: 6vw;
        width: 12vw;
        margin: 0 2vw;
      }
    }

    .coming-soon {
      color: var(--ion-color-danger);
      font-weight: bold;
    }
  }

  .location-count-selector {
    > span {
      font-size: 1.1em;
      border-radius: .8vw;
      margin: 0 -.2vw;
      border: .4vw solid var(--ion-color-light-shade);
      padding: 2vw;
      display: inline-flex;
      > span {
        padding:.8vw 1.6vw;
        font-size: 3vw;
      }
      &.selected > span {
        background: var(--ion-color-light-shade);
      }
    }
  }
}
