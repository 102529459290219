.network-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
    text-align: center;
    padding: 0 10%;
    ion-icon {
        display: block;
        margin: 0 auto;
        font-size: 96px;
    }
}
@import "responsive";
