#instagram-connection {
  @media screen and (min-width: 600px) {
    .connection-logos {
      margin: 60px 0;

      > img, > div {
        width: 60px;
        height: 60px;
      }

      ion-icon {
        font-size: 60px;
        margin: 0 12px;
      }

      .social-media-logos {
        position: relative;

        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
