.coming-soon-image {
	height: 50vw;
	background-size: cover;
	background-image: url("/assets/coming-soon-placeholder.jpg");
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 25px;
	margin-top: 20vw;
	overflow: hidden;
	.coming-soon-copy {
		background: rgba(0, 0, 0, .5);
		padding: 2.5vw 3vw;
		margin: 25vw 0 0;

		h2, p {
			margin: 0 0;
			color: white;
			text-align: left;
		}

		h2 {
			font-size: 8vw;
		}

		p {
			text-transform: uppercase;
			font-size: 2.25vw;
			font-family: serif;
			margin-top: 1vw;
		}
	}
}
