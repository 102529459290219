.billing-history-page {
  .billing-history-item {
    --padding-start: 0;
    .details {
      font-size: 4vw;
      padding: 2vw 0 3vw;
    }
    .amount {
      padding: 2vw 0 3vw;
    }
  }
}
