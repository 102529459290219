@media screen and (min-width: 600px) {
    #accepted-invitations-page {
        h4 {
            padding: 30px 0 60px;
            font-size: 48px;
        }
    }
    .invite-link {
        bottom: 60px;
        ion-button {
            max-width: 480px;
        }
    }
}
