.label-in-border {
  @media screen and (min-width: 600px) {
    margin: 0 0 18px;
    .label {
      font-size: 24px;
      margin: 0 0 -15px 6px;
      padding: 0 6px;
    }
    .char-count {
      font-size: 27px;
      margin: 6px 0 12px;
    }
  }
}
