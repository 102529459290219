#splash-page {
	text-align: center;
	.mobile-splash {
		--background: url('/assets/intro-bg.png');
		#splash-content {
			display: flex;
			height: 100%;
			flex-direction: column;

			#headline-wrapper {
				flex-grow: 1;
				padding-top: 25vw;
				.main-logo img {
					width: 40vw;
				}
				p {
					color: var(--ion-color-primary);
					font-size: 7vw;
					font-weight: bold;
					padding: 0 10vw;
					margin-top: 12vw;
				}
			}
			#actions {
				padding-bottom: 10vw;
				ion-button {
					font-size: 5vw;
					max-width: 85vw;
					margin: 4vw 0;
					font-weight: bold;
					--padding-top: 4vw;
					--padding-bottom: 4vw;
					height: auto;
				}

				#sign-in-line {
					text-align: center;
					width: 100%;
					margin: 4vw 0;
					a {
						font-weight: bold;
						font-size: 5vw;

					}
				}
				a {
					color: white;
				}
				hr {
					background: white;
					height: 1px;
					max-width: 55vw;
					margin: 0 auto;
				}

				.footnote {
					max-width: 55vw;
					color: white;
				}
			}
		}
	}
	.desktop-splash {
		--padding-top: 0;
		--padding-end: 0;
		--offset-bottom: auto!important;
		--overflow: hidden;
		overflow: auto;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		> div {
			height: 100%;
			margin-bottom: 10px;
			padding: 40px 0;
			h2 {
				color: black;
				text-align: left;
				font-weight: bold;
			}
			p {
				text-align: left;
			}
			&.home-section .side-by-side {
				> img {
					width: 50%;
				}
				.content {
					width: 40%;
					margin: 40px 5%;
				}
			}
			&.about-section .side-by-side {
				> img {
					width: 40%;
				}
				.content {
					width: 50%;
					margin: 40px 0 40px 5%;
					text-align: left;
					ion-button {
						width: 60%;
					}
				}
			}
		}
	}
}

@import "responsive";
