.no-business-followers {
  @media screen and (min-width: 600px) {
    ul {
      padding: 0 0 0 48px;
    }
    .share-link-section {
      .location-url-display {
        padding: 12px;
        font-size: 18px;
      }

      ion-button {
        width: 270px;
        margin: 30px 0 0;
      }
    }
  }
}
.business-followers-list {
  ion-item {
    @media screen and (min-width: 600px) {
      margin: 30px 0;
      .profile-image {
        margin: 0 30px 0 0;
      }
      .follower-details {
        p {
          font-size: 21px;
        }
      }
    }
  }
}
