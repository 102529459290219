#delete-account-page {
  ion-button {
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 6vw;
    margin-top: 20vw;
  }
  .delete-alert .alert-wrapper {
    .back-button, .delete-button {
      padding: .2px;
      font-weight: normal;
      background-color: grey; /*needs to be updated */
    }
    .delete-button {
      color: var(--ion-color-secondary);

    }
  }
}
@import "responsive";
