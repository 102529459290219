#accepted-invitations-page {
    h4 {
        padding: 5vw 0 10vw;
        font-size: 8vw;
        color: var(--ion-color-medium);
        font-weight: normal;
    }
    .accepted-invites-list {
        .user-item {
            --padding-start: 0;
            h6 {
                color: var(--ion-color-dark);
                font-size: 16px;
            }
        }
    }
    .invite-link{
        width: 100%;
        bottom: 10vw;
        ion-button {
            display: block;
            max-width: 80vw;
            margin: 0 auto;
            font-weight: bold;
            font-size: 1.2em;
        }
    }
}

@import 'responsive';
