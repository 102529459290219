.invitation-code-step {

    .content-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .footnote{
        max-width: 70vw;
        padding-bottom: 5vw;
        a {
          font-weight: normal;
          color: var(--ion-color-medium);
          strong {
            color: var(--ion-color-primary);
          }
        }
      }
    }
}
