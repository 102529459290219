.business-list-page {
  ion-content {
    --padding-bottom: 16px;
  }
  ion-item {
    --padding-start: 0;
    margin: 0 0 16px;
    cursor: pointer;
    .item-content {
      display: flex;
      flex-direction: column;
      p, h6 {
        margin: 0;
        font-size: 4vw;
      }
    }
    ion-button {
      width: auto;
    }
  }
}
@import "responsive";
