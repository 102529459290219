.permission-prompt {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background: white;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;

  .location-icon {
    border: 5px solid var(--ion-color-light-shade);
    background: var(--ion-color-light);
    height: 35vw;
    width: 35vw;
    border-radius: 100px;
    padding: 5vw;
    margin: 12vw auto 8vw;

    ion-icon {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
      color: var(--ion-color-medium-tint);
    }
  }

  h4 {
    color: var(--ion-color-medium);
  }

  .sub-header {
    font-size: 5.75vw;
    margin: 5vw;
  }

  ion-button {
    margin: 12vw 0 0 !important;
  }

  .disclaimer {
    text-transform: uppercase;
    font-size: 14px !important;
    margin: 10vw 5vw;
    color: var(--ion-color-secondary);

    a {
      font-weight: normal !important;
      color: var(--ion-color-secondary);
      text-decoration: underline;
    }
  }
}
@import "responsive";
