.organization-template {
  height: 100%;
  display: flex;
  flex-direction: column;
  .split-pane-wrapper {
    position: relative;
    height: 100%;
  }
}
@import "responsive";
