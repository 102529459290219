.post-item {
  --padding-top: 3vw;
  --padding-bottom: 3vw;
  cursor: pointer;
  .post-item-details {
    flex-grow: 1;
    max-width: 100%;
    border-radius: 0;
    p {
      margin: 0;
    }
    .post-date {
      text-align: right;
      font-size: 4vw;
    }
    .likes-count {
      justify-content: flex-end;
      color: var(--ion-color-primary);
      font-size: 3vw;
      display: flex;
      ion-icon {
        font-size: 4vw;
      }
    }
  }
}
@import "responsive";
