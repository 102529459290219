.color-selector {
  padding-top: 5px;
  p {
    text-align: center;
  }
  .color-squares {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    .color-square {
      width: 7vw;
      height: 7vw;
      margin: 0 3px;

      &.white{
        border: 1px solid var(--ion-color-secondary);
      }
      &.active{
        width: 10vw;
        height: 10vw;
      }
    }
  }
}
@import "responsive";
