.post-actions-wrapper {
  padding-right: 10px;
  ion-button {
    margin: 10px 0;
    height: 35px;
  }
  .post-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    ion-button {
      width: auto;
    }

    ion-icon {
      font-size: 7.5vw;
    }

    .post-action-callout {
      width: 11.5vw;
      height: 11vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        color: white;
        font-size: .7em;
      }
      &.save ion-icon {
        font-size: 6.5vw;
        margin-top: .5vw;

      }
      &.share p {
        margin-left: -8px;
      }
    }
  }
}
@import "responsive";
