#splash-page {
  .mobile-splash {
    #splash-content {
      @media screen and (min-width: 600px) {
        #headline-wrapper {
          padding-top: 150px;

          .main-logo img {
            width: 240px;
          }

          p {
            font-size: 42px;
            padding: 0 60px;
            margin-top: 72px;
          }
        }
        #actions {
          padding-bottom: 60px;

          ion-button {
            font-size: 30px;
            max-width: 510px;
            margin: 24px 0;
            --padding-top: 24px;
            --padding-bottom: 24px;
          }

          #sign-in-line {
            margin: 24px 0;

            a {
              font-size: 30px;
            }
          }

          hr {
            max-width: 330px;
          }

          .footnote {
            max-width: 330px;
          }
        }
      }
    }
  }
}