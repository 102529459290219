#albums-page {
	.no-albums-message{
		padding: 0 5vw;
		h2 {
			font-weight: 600;
		}
	}
}

@import "responsive";
