.business-profile {
  position: relative;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  .no-posts {
    text-align: center;
    h4 {
      color: var(--ion-color-dark);
      max-width: 192px;
      margin: 16px auto;
    }
    ion-icon {
      color: var(--ion-color-primary);
      width: 33vw;
      height: 33vw;
    }
  }
  .no-subscription-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 64px;
  }
}
