.account-switcher-options {
  ion-item {
    display: block;
    --padding-top: 5px;
    --padding-bottom: 5px;
    cursor: pointer;
    .checkmark {
      color: var(--ion-color-primary);
    }
  }
  .add-business-button {
    ion-icon {
      width: 15vw;
    }
  }
}
.account-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@import "responsive";
