#contact-us-page {
  .side-by-side {
    margin: 0;
    > * {
      max-width: 48%;
    }
  }
  ion-button {
    padding: 10vw 0;
  }
}
