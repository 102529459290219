.business-editor {
  .header-images {
    .featured-images {
      @media screen and (min-width: 784px) {
        height: 200px;
      }
    }
  }
  .location-edit-links {
    ion-item {
      span {
        @media screen and (min-width: 564px) {
          font-size: 24px;
        }
      }
    }
  }
}
