.thread-item {
  padding: 0 !important;
  ion-button{
    display: inline-block;
    width: auto;
    max-height: 19px;
  }
  > .message-item {
    padding: 13px 0;
    p {
      margin: 3px 0;
    }
    .replies {
      color: var(--ion-color-primary);
    }
  }
  .thread-replies {
    padding: 0 0 0 16px;
  }
  .more-link {
    --background: transparent;
    --background-activated: transparent;
    color: var(--ion-color-medium);
    font-style: italic;
    &.orange {
      color: var(--ion-color-primary);

    }
  }
}
