.location-editor {
  ion-content {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 55px;
  }
  .profile-image-editor {
    text-align: center;
    padding: 0 3.125vw;
    display: flex;
    justify-content: center;
    p {
      text-align: left;
      padding: 10vw 0;
      margin: 0 6px;
      font-size: 3vw;
      width: 25%;
    }
    .profile-image {
      display: block;
      margin: 3.125vw auto;
      width: 25vw;
      height: 25vw;
    }

    .asset-uploader {
      position: relative;
      margin: 0;
      display: inline-block;
      ion-icon {
        background: white;
        border-radius: 15.625vw;
        position: absolute;
        bottom: -3.125vw;
        left: 0;
        right: 0;
        margin: auto;
        &.profile-image-icon {
          right: 0;
          padding: 1.5625vw;
          bottom: 3.125vw;
          left: auto;
          box-shadow: .625vw .625vw 1.5625vw -0.625vw black;
        }
      }
    }
  }

  .location-edit-links {
    padding-bottom: var(--ion-safe-area-bottom);
    ion-item {
      --padding-start: 0;
      align-items: start;
      span {
        font-size: 4.25vw;
        align-self: center;
        &.add-link {
          color: var(--ion-color-secondary);
        }
        &.current-value {
          font-style: italic;
          color: var(--ion-color-medium);
        }
      }
      .edit-icon {
        margin-right: 0;
      }
    }
  }
  .bottom-sticky-section {
    background: var(--ion-color-light);
    padding-bottom: var(--ion-safe-area-bottom);
  }
}

@import "responsive"
