.location-details-wrapper {
  .location-images {
    @media screen and (min-width: 600px) {
      height: 150px;
      .profile-image {
        width: 150px;
        height: 150px;
      }
      .featured-images {
        height: 138px;
        left: 105px;
      }
    }
  }
  .location-details-text {
    #location-name {
      @media screen and (min-width: 505px) {
        font-size: 24px;
      }
    }
    p {
      @media screen and (min-width: 600px) {
        font-size: 24px;
      }
    }
    ion-button {
      @media screen and (min-width: 600px) {
        min-width: 126px;
        width: 126px;
        height: 48px;
        font-size: 18px;
      }
    }
  }
}

.location-ctas {
  @media screen and (min-width: 600px) {
    padding-top: 18px;
  }
}
