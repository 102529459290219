.drop-shadow-router-link {
  border: 1px black solid;
  box-shadow: 5px 5px #bbb;
  font-weight: bold;
  margin: 6vw auto;
  font-size: 4vw;
  --padding-top: 6px;
  --padding-bottom: 6px;
  p,
  h6 {
    margin: 0;
  }
  p {
    font-weight: normal;
  }
}
@import "responsive";
