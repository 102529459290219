.post-response-page{
  ion-item {
    padding: 0 0 6vw;
    p {
      font-size: 3.75vw;
      color: black;
    }
  }
  .no-interaction{
    padding: 8vw 6.5vw;
    text-align: center;
    color: var(--ion-color-medium);
    h4{
      color: var(--ion-color-medium);
    }
    ion-icon{
      font-size: 160px;
      padding: 6vw 0;
    }
    .subhead{
      font-weight: bold;
    }
  }
}

@import "responsive";
