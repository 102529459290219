.location-details-wrapper {
  width: 100%;
  .location-images {
    width: 100%;
    height: 25vw;
    position: relative;
    .profile-image {
      width: 25vw;
      height: 25vw;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }
    .featured-images {
      height: 23vw;
      left: 17.5vw;
      right: 0;
      position: absolute;
      top: 3px;
    }
  }
  .location-details-text {
    padding: 6px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    #location-name {
      font-size: 4.75vw;
      color: black;
    }
    p {
      margin: 0;
      font-size: 4vw;
    }
    ion-button {
      min-width: 21vw;
      width: 21vw;
      height: 8vw;
      font-size: 3vw;
      --border-radius: 5px;
      &.button-outline {
        --border-radius: 2px;
      }
    }
  }
}

.location-ctas{
  display: flex;
  flex-wrap: wrap;
  padding-top: 3vw;
  ion-button{
    flex-grow: 1;
    flex-basis: 32%;
    --background: transparent;
    --background-activated: transparent;
    --border-color: var(--ion-color-primary);
    --border-width: 2px;
    --border-style: solid;
    --border-radius: 4px;
    --color: var(--ion-color-primary);

  }
}

@import "responsive";
