#qualifying-guidelines-page{

    ion-content{
        --padding-top: 0;
    }

    h4 {
        margin: 2em;
        text-align: center;
        font-weight: normal;
    }
    p{
        margin-top: 0;
        margin-bottom: .5em;
    }
    .guideline{
        margin: .5em 0 1.5em;
        ion-item{
            --padding-start: 0;
            --min-height: 30px;
            ion-label{
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .footnote,
    ion-button{
        margin-top: 2em;
    }
    .footnote {
        padding: 0 1.5vw;
    }
}
@import "src/pages/OrganizationCreator/responsive";
