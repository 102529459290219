#business-information-form {
  ion-button{
    margin-top: 16vw;
  }
  ion-item {
    --padding-start: 0;
    ion-icon {
      width: 15vw;
    }
    .checkmark {
      margin: 0 0 0 2vw;
      width: 5vw;
    }
  }
  ion-label {
    display: block;
    padding-left: 2.5vw;
    padding-bottom: 3vw;
    font-size: 1.2em;
    &:not(:first-of-type){
      padding-top: 5vw;
    }
  }
  .footnote{
    font-style: italic;
    font-size: .85em;
  }
  .industry-selector{
    background: var(--ion-color-light);
    border-color: transparent;
  }
}
@import "responsive";
