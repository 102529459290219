.organization-dashboard-tabbed-page {
  ion-content {
    --padding-bottom: 50px;
  }
  .bottom-sticky-section {
    background: white;
    ion-button {
      max-width: 40vw;
      line-height: 32px;
      border: 1px white solid;
      box-shadow: .625vw .625vw 1.5625vw -0.625vw var(--ion-color-medium);
      border-radius: 16px;
      --border-radius: 16px;
      float: right;
      margin: 0 16px 16px;
    }
  }
}
.post-creator-modal {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  --background:transparent;
  --box-shadow: none;
  --height: 95vh;
  --width: 80vh;
  --border-radius: 5px;
  .modal-wrapper {
    background: transparent;
  }
  #ion-react-wrapper {

    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  &.preview #ion-react-wrapper {
    transform: rotateY(180deg);
  }

  .business-form-content, .business-preview-content {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-color: white;
    color: black;
    z-index: 100;
  }
  .business-preview-content {
    transform: rotateY(180deg) translateZ(1px);
    z-index: -1;
    --padding-bottom: 60px;
    text-align: left;
    .bottom-sticky-section .side-by-side {
      justify-content: center;
      ion-button {
        width: 200px;
        flex-grow: 0;
        margin: 5px;
      }
    }
  }
  &.preview .business-preview-content {
    z-index: 100;
  }
}
.post-published-modal {
  .ion-page-invisible {
    opacity: 1;
  }
}
@import "responsive";
