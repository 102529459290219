#business-information-form {
  @media screen and (min-width: 600px) {
    ion-button{
      margin-top: 96px;
    }
    ion-item {
      ion-icon {
        width: 90px;
      }
      .checkmark {
        margin: 0 0 0 12px;
        width: 30px;
      }
    }
    ion-label {
      padding-left: 15px;
      padding-bottom: 18px;
      &:not(:first-of-type){
        padding-top: 30px;
      }
    }
  }
}
