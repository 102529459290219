.post-preview-page {
  .post-preview-main-content {
    --padding-bottom: 24vw;
    .post-details-content-wrapper {
      border-radius: 20px;
      overflow: hidden;
      perspective: 1px;
    }
    .bottom-sticky-section {
      background: white;
      padding: 2vw 4vw;
      border-top: .1vw var(--ion-color-medium-shade) solid;
      ion-button {
        margin: 0 1vw;
      }
    }
  }
}
