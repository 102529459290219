.thread-list {
  border-bottom: 1px solid var(--ion-color-medium);
  display: flex;
  flex-direction: column;
  height: 100%;
  ion-list {
    overflow-y: scroll;
    padding: 12px;
  }
}
.headline-comments{
  h5{
    margin: 0;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    color: var(--ion-color-medium);
    ion-icon{
      float: right;
    }
  }
}
