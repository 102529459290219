.news-feed-settings {
  ion-toolbar {
    --padding-start: 10px;
    --padding-end: 10px;
    height: auto !important;
    max-height: 80px;
    min-height: 80px;
    overflow: hidden;
    transition: max-height .2s linear, min-height .2s linear;
    .settings-information {
      display: flex;
      justify-content: space-between;
      padding: 2vw 15px;
      background: black;
      border-radius: 10px;
      align-items: center;
      .settings-content {
        h3, p {
          margin: 0;
          font-style: italic;
        }
        p{
          color: var(--ion-color-light);
          font-weight: 400;
          strong{
            font-weight: 700;
          }
        }
      }
      ion-icon {
        background: var(--ion-color-light);
      }
    }
  }
  &.hide {
    ion-toolbar {
      max-height: 0 !important;
      min-height: 0 !important;
    }
  }
}
