.accept-invitation-page {
  h2 {
    color: var(--ion-color-medium);
    font-weight: bold;
  }

  ion-input {
    border: none;
    width: 12ch;
    background:
            repeating-linear-gradient(90deg,
                    black 0,
                    black 1.5ch,
                    transparent 0,
                    transparent 1.75ch)
            0 90%/86% 2px no-repeat;
    color: black;
    --padding-start: .25ch;
    font: 4ch consolas, monospace;
    letter-spacing: .75ch;
    overflow:hidden;
  }

  .next-arrow {
    margin: 7.5vw 0;
  }
  .invitation-accepted {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-top: var(--ion-safe-area-top);
    padding-bottom: var(--ion-safe-area-bottom);
    .side-by-side {
      margin: 1.5vw 0;
      img {
        max-width: 48%;
        width: auto;
        height: auto;
        margin-bottom: 10vw;
      }
    }
    ion-button {
      margin-bottom: 10vw;
    }
  }
  .not-logged-in {
    ion-button {
      max-width: 60%;
    }
  }
}

@import "resonsive";
