@media screen and (min-width: 600px) {
  .post-published-details {
    .profile-image {
      height: 72px;
      min-width: 72px;
      width: 72px;
      margin-right: 12px;
    }
    p {
      font-size: 21px;
    }
    .follow-button-wrapper{
      width: 126px;
    }
  }
}
