.user-recommendation-list{
  .user-recommendation-item{
    --padding-start: 0;
    --ion-safe-area-left: 0;
    --padding-end: 0;
    --ion-safe-area-right: 0;
    cursor: pointer;
    .user-recommendation-item-content {
      padding-top: 10px;
      width: 100%;
      .post-content {
        p, span {
          font-size: 17px !important;
        }
      }
    }
    p {
      font-weight: normal !important;
      text-align: left !important;
      white-space: pre-line;
    }
    .post-link-header{
      display:flex;
      .post-location-details {
        strong {
          color: var(--ion-color-dark);
        }
        small {
          font-size: .8em;
          font-weight: bold;
        }
      }
      p{
        margin: 0;
      }
      .posted-time {
        font-weight: normal;
        font-size: .8em;
      }
      h6{
        margin: 0;
        color: var(--ion-color-dark);
        font-weight: bold;
        font-size: 1em;
      }
      .post-preview-thumbnail {
        height: 15vw;
        width: 15vw;
        flex-basis:15vw;
        border-radius: 4px;
        background-position: center;
        background-size: cover;
        margin: 0 10px 0 0;
      }
      .post-header-details{
        flex-grow: 1;
      }
    }
  }
}

.post-list-action-sheet-options {

  .action-sheet-group {
    .action-sheet-button {
      .action-sheet-button-inner {
        color: var(--ion-color-medium);
      }
      &.action-sheet-delete .action-sheet-button-inner {
        color: var(--ion-color-danger);
      }
    }
  }
}

@import "respomsive";
