#change-password-page {
  .form-wrapper {
    .profile-image {
      width: 37.5vw;
      height: 37.5vw;
      margin: 3.125vw auto 6.25vw;
    }
    h4 {
      text-align: center;
    }
    ion-button {
      max-width: 75vw;
      margin: 0 auto;
      display: block;
    }
  }
}
