.post-editor-form {
  .asset-uploader {

    @media screen and (min-width: 600px) {
      height: 600px;
      p {
        font-size: 24px;
      }
    }
    ion-icon {

      @media screen and (min-width: 568px) {
        width: 64px;
        height: 64px;
      }
    }
  }
  @media screen and (min-width: 600px) {
    .label-in-border {
      margin: 12px 0 6px;
    }
    .button-section {
      margin: 24px auto;
      .toggle-line {
        margin: 6px 0;
        --padding-start: 12px;
        --padding-end: 12px;

      }

      .text-input-with-label-in-border {
        padding: 0 30px;
      }

      .color-selector {
        padding: 0 0 30px;
      }
    }
  }
  .grey-background-section {
    @media screen and (min-width: 600px) {
      margin-bottom: 18px;
    }

    @media screen and (min-width: 640px) {
      .side-by-side {
        ion-icon {
          width: 64px;
          height: 64px;
          margin-right: 32px;
        }

        p {
          font-size: 28px;
          width: 480px;
        }
      }
    }
  }

  .followers-check-line {
    ion-checkbox.md {
      @media screen and (min-width: 600px) {
        margin-right: 18px;
      }
    }
    ion-item {
      @media screen and (min-width: 600px) {
        --padding-start: 12px;
      }
      @media screen and (min-width: 640px) {
        font-size: 32px;
      }
    }
    ion-icon {
      @media screen and (min-width: 640px) {
        width: 64px;
        height: 64px;
      }
    }
  }
}
.bottom-sticky-section.submit-post-sticky-section {
  @media screen and (min-width: 800px) {
    .side-by-side {
      justify-content: center;
      ion-button {
        width: 200px;
        flex-grow: 0;
      }
    }
  }
}
