@media screen and (min-width: 600px) {
  .no-posts-wrapper {
    .no-posts-details {
      margin: 0 36px;

      ion-icon {
        font-size: 120px;
        margin: 0 auto 30px;
      }
      h4 {
        font-size: 42px;
      }
    }
  }
}
