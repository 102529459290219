.user-recommendation-list{
  .user-recommendation-item{
    .post-link-header{
      @media screen and (min-width: 600px) {
        .post-preview-thumbnail {
          height: 90px;
          width: 90px;
          flex-basis: 90px;
        }
        .post-header-details {
          padding: 0 12px;
        }
      }
    }
  }
}