.location-editor {
  .profile-image-editor {
    @media screen and (min-width: 640px) {
      padding: 0 20px;
    }
    p {
      @media screen and (min-width: 600px) {
        font-size: 18px;
      }
      @media screen and (min-width: 800px) {
        padding: 80px 0;
      }
    }
    .profile-image {
      @media screen and (min-width: 640px) {
        margin: 20px auto;
      }
      @media screen and (min-width: 800px) {
        width: 200px;
        height: 200px;
      }
    }
  }

  .location-edit-links {
    ion-item {
      span {
        @media screen and (min-width: 564px) {
          font-size: 24px;
        }
      }
    }
  }
}
