#followers-page {
  ion-item {
    margin: 5vw 0;
    p {
      font-size: 4vw;
      color: black;
    }
    ion-button {
      width: 20vw;
      height: 9vw;
      visibility: visible;
      button {
        font-size: 4vw;
      }
    }
  }
  #no-followers {
    text-align: center;
    --padding-top: 15vw;
    --padding-start: 7vw;
    --padding-end: 7vw;
    ion-icon {
      margin: 10vw 0 5vw;
      width: 40vw;
      height: 40vw;
      color: var(--ion-color-primary);
    }
    h4 {
      font-size: 5vw;
      color: var(--ion-color-medium);
    }
    p {
      font-size: 4vw;
    }
    ion-button {
      max-width: 75%;
    }
  }
}
@import "responsive";
