@media screen and (min-width: 800px) {

  .member-post-creator-page {
    .member-post-creator-form {
      .post-form-content {
        .image-selector {

          > div {
            width: 160px;
            height: 160px;
            border: 8px solid white;
            margin: 0 12px 0 0;

            &.selected {
              border: 8px solid var(--ion-color-success)
            }

            &.image-uploader-placeholder {
              ion-icon {
                font-size: 96px;
              }
            }
          }
        }
      }
    }
  }
}
