.featured-images {
  display: flex;
  justify-content: space-between;
  > div {
    height: 100%;
    width: 33%;
  }
  .image-entry,
  .placeholder {
    background-image: url("/assets/public-placeholder.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }
  .asset-uploader {
    .image-entry,
    .placeholder {
      background-image: url("/assets/image-placeholder.png");
    }
  }
}
