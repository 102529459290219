.user-management-page {
  .btn-add-user {
    width: auto;
    float: right;
  }
  ion-list {
    clear: both;
    ion-item {
      --padding-start: 0;

      ion-button {
        width: auto;
        margin: 0;
      }
    }
  }
}
