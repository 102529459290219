.expandable-content-area {
  .header {
    display: flex;
    justify-content: space-between;
    background: var(--ion-color-light);
    padding: 1.5625vw 6.25vw;
    cursor: pointer;
  }
  .content {
    padding: 1.5625vw 6.25vw;
  }
}
