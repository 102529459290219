.business-editor {
  ion-content {
    --padding-bottom: 55px;
  }
  .header-images {
    text-align: center;
    padding: 0 10px;
    .featured-images {
      height: 25.5vw;
      margin: 3.125vw 0 6.25vw;
      > div {
        width: 32%;
      }
      .placeholder {
        background-image: url("/assets/add-image-placeholder.png");
      }
    }

    .asset-uploader {
      position: relative;
      margin: 0 auto;
      display: inline-block;
      ion-icon {
        background: white;
        border-radius: 15.625vw;
        padding: 1.5625vw;
        position: absolute;
        bottom: -3.125vw;
        left: 0;
        right: 0;
        margin: auto;
        box-shadow: .625vw .625vw 1.5625vw -0.625vw black;
        &.profile-image-icon {
          right: 0;
          bottom: 3.125vw;
          left: auto;
        }
      }
    }
  }

  .location-edit-links {
    padding-bottom: var(--ion-safe-area-bottom);
    ion-item {
      --padding-start: 0;
      align-items: start;
      span {
        font-size: 4.25vw;
        align-self: center;
        &.add-link {
          color: var(--ion-color-secondary);
        }
        &.current-value {
          font-style: italic;
          color: var(--ion-color-medium);
        }
      }
      .edit-icon {
        margin-right: 0;
      }
    }
  }
  .bottom-sticky-section {
    background: var(--ion-color-light);
    padding-bottom: var(--ion-safe-area-bottom);
  }
}

@import "responsive";
