@media screen and (min-width: 480px) {
    .send-invite-modal {
        .invite-friends {
            padding: 10px;

            h4 {
                font-size: 48px;
            }
        }
    }
}
@media screen and (min-width: 600px) {
    .send-invite-modal {
        .invite-friends {
            p {
                font-size: 24px;
            }
        }

        .invite-link {
            width: 100%;
            bottom: 60px;

            ion-button {
                max-width: 480px;
                font-size: 1.2em;
            }
        }
    }
}
