ion-header.logged-out {
    --color: white;
    ion-toolbar {
        --padding-start: 20px;
        --padding-end: 20px;
        --min-height: 120px;
        --border-color: transparent;
        ion-back-button {
            color: var(--ion-color-secondary);
        }
        ion-icon{
            color: var(--ion-color-primary);
            width: 8vw;
            height: 10vw;
        }
        .back-button{
            --color: var(--ion-color-secondary);
            --background: white;
        }
        .swipe-logo {
            max-width: 20vw;
            max-height: 105px;
        }
    }
}
@import "responsive";
