#delete-account-page {
  ion-button {
    @media screen and (min-width: 600px) {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 36px;
      margin-top: 120px;
    }
  }
}
