
.organization-menu-content {
  h4 {
    @media screen and (min-width: 436px) {
      font-size: 24px;
    }
    @media screen and (min-width: 700px) {
      margin-top: 28px;
    }
    &.account-admin-header {
      @media screen and (min-width: 450px) {
        margin-top: 36px;
      }
      @media screen and (min-width: 466px) {
        margin-bottom: 28px;
      }
    }
  }
  @media screen and (min-width: 450px) {
    ion-item {
      font-size: 18px;
    }
  }
}
