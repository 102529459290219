#profile-incomplete {
  text-align: center;
  padding: 10vw 10vw 0;
  h4 {
    font-size: 5vw;
    color: var(--ion-color-medium);
  }
  p {
    font-size: 4vw;
  }
  ion-button {
    max-width: 100vw;
    font-size: 1.2em;
    --color: white;
    &.button-disabled{
      --background: var(--ion-color-medium);
      --color: var(--ion-color-medium-tint);
    }
  }
  .asset-uploader{
    padding: 10vw 0 5vw;
    .profile-image{
      width: 35vw;
      height: 35vw;
      margin: 0 auto;
      .plus-sign{
        height: 23%;
        bottom: 2%;
      }
    }
  }
}
