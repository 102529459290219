#organization-menu {
  pointer-events: auto;
}
.organization-template {
  height: 100%;
  display: flex;
  flex-direction: column;
  .split-pane-wrapper {
    position: relative;
    height: 100%;
    background: var(--ion-color-light);
    ion-split-pane {
      max-width: 960px;
      margin: 0 auto;
    }
  }
}
