.referrals-content {
    --padding-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .no-referrals-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    h3 {
        color: var(--ion-color-dark);
        text-align: left;
    }
    h4{
        text-align: center;
        padding-top: 5vw;
    }
}
@import "responsive";