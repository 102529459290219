#settings-page {
  ion-content.settings-menu {
    @media screen and (min-width: 600px) {
      --padding-top: 60px;
    }
    h4 {
      @media screen and (min-width: 640px) {
        padding: 20px 30px;
      }
    }

    ion-item {
      @media screen and (min-width: 600px) {
        font-size: 30px;
      }
      @media screen and (min-width: 640px) {
        padding: 0 50px;
      }
    }
    .bottom-sticky-section {
      @media screen and (min-width: 600px) {
        margin-bottom: 30px;
        ion-button {
          padding-left: 30px;
          padding-right: 30px;
          font-size: 36px;
        }
      }
    }
  }
}
