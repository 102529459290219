.business-creation-header{
	min-height: 35vw;
	display: flex;
	&.no-logo{
		min-height: auto;
	}
	ion-toolbar {
		flex-grow: 1;
		display: flex;
		&.closeToolbar{
			--color: var(--ion-color-primary);
			--background: white;
			border-bottom: none;
			ion-router-link {
			    display: block;
			    --color: var(--ion-color-medium);
			    visibility: visible;
			    ion-icon{
			        font-size: 24px;
			    }
			}
			.back-icon{
				font-size: 30px;
				color: var(--ion-color-primary);
			}
		}
		ion-title{
			height: auto;
			margin-top: 10px;
		}
	}
}
.plt-android {
	.business-creation-header {
		ion-back-button, .back-icon {
			display: none;
		}
	}
}
@import "responsive";
