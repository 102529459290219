.referral-tutorial-modal {
  @media screen and (min-width: 548px) {
    p {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 576px) {
    h4 {
      font-size: 36px;
    }
  }
  @media screen and (min-width: 589px) {
    h3 {
      font-size: 40px;
    }
  }
}

@media screen and (min-width: 600px) {
  .referral-tutorial-modal {
    --max-width: 700px;
    --min-width: none;
    --height: 85%;
    ol {
      li {
        font-size: 30px;
      }
    }
    .disclaimer {
      font-size: 30px;
    }
  }
}
