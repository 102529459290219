@media screen and (min-width: 600px) {
  .dummy-tab-content {
    .content-wrapper {
      padding-bottom: 96px;
    }
    .dummy-tabs {
      padding: 6px;
      ion-icon {
        font-size: 48px;
        &.primary-tab{
          font-size: 72px;
          height: 72px;
          width: 69px;
        }
      }
    }
  }
}

