.welcome-template {
  height: 100%;
  display: flex;
  flex-direction: column;
  .split-pane-wrapper {
    position: relative;
    height: 100%;
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
  }
}
@import "responsive";
