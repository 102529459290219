.post-list {
  .post-item {
    .post-preview-thumbnail {
      @media screen and (min-width: 600px) {
        margin: 0 30px 0 0;
      }
      @media screen and (min-width: 960px) {
        height: 240px;
        width: 240px;
      }
      .comments-button {
        p {
          @media screen and (min-width: 800px) {
            font-size: 20px;
          }
        }
      }
    }
    .post-preview-content {
      .post-status-line {
        @media screen and (min-width: 800px) {
          font-size: 24px;
        }
        @media screen and (min-width: 1000px) {
          margin: 20px 0;
          ion-icon {
            font-size: 60px;
            margin-top: -10px;
          }
        }
      }
      .post-statistics {
        .post-statistic {
          @media screen and (min-width: 914px) {
            h4 {
              font-size: 32px;
            }
          }
          h3 {
            @media screen and (min-width: 800px) {
              margin: 16px 0;
            }
            @media screen and (min-width: 698px) {
              font-size: 48px;
            }
          }
        }
      }
    }
  }
}
