.no-shop-listings {
  text-align: center;
  padding: 15% 3% 0;
  ion-icon {
    margin: 5vw 0;
    height: 40vw;
    width: 40vw;
    color: var(--ion-color-medium);
  }
  h4 {
    font-size: 5vw;
    color: var(--ion-color-medium);
  }
  p {
    font-size: 4vw;
  }
  ion-button {
    max-width: 55%;
    &.button-disabled{
      --background: var(--ion-color-medium);
      color: var(--ion-color-medium-tint);
    }
  }
}
