@media screen and (min-width: 992px) {
  #album-page {
        h2 {
        padding-bottom: 0;
      }
      .album-post-list{
        justify-content: flex-start;
        .post-item {
          flex-basis: 25%;
          overflow: visible;
          padding: 0 10px;
          .post-content {
            position: relative;
            width: 100%;
            .album-post-thumbnail-placeholder, .album-post-thumbnail {
              width: 100%;
              height: 0;
              padding: 50%;
            }
          }
        }
      }
    }
}
