@font-face {
  font-family: 'Omnes';
  src: url('/assets/fonts/Omnes.eot');
  src: url('/assets/fonts/Omnes.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Omnes.woff2') format('woff2'),
  url('/assets/fonts/Omnes.woff') format('woff'),
  url('/assets/fonts/Omnes.ttf') format('truetype'),
  url('/assets/fonts/Omnes.svg#Whitney-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Omnes';
  src: url('/assets/fonts/Omnes-Semibold.woff2') format('woff2'),
  url('/assets/fonts/Omnes-Semibold.woff') format('woff'),
  url('/assets/fonts/Omnes-Semibold.ttf') format('truetype'),
  url('/assets/fonts/Omnes-Semibold.svg#Whitney-Medium') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Omnes';
  src: url('/assets/fonts/Omnes-Light-Italic.woff2') format('woff2'),
  url('/assets/fonts/Omnes-Light-Italic.woff') format('woff'),
  url('/assets/fonts/Omnes-Light-Italic.ttf') format('truetype'),
  url('/assets/fonts/Omnes-Light-Italic.svg#Whitney-Medium') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'bukhari_scriptregular';
  src: url('/assets/fonts/bukhari_script.woff2') format('woff2'),
  url('/assets/fonts/bukhari_script.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gagalinregular';
  src: url('/assets/fonts/gagalin-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/gagalin-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'nexa_scriptfree';
  src: url('/assets/fonts/nexascript-free-webfont.woff2') format('woff2'),
  url('/assets/fonts/nexascript-free-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'chewyregular';
  src: url('/assets/fonts/chewy-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/chewy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlow';
  src: url('/assets/fonts/barlowsemicondensed-bolditalic-webfont.woff2') format('woff2'),
  url('/assets/fonts/barlowsemicondensed-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;

}

@font-face {
  font-family: 'barlow';
  src: url('/assets/fonts/barlowsemicondensed-blackitalic-webfont.woff2') format('woff2'),
  url('/assets/fonts/barlowsemicondensed-blackitalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;

}

@font-face {
  font-family: 'barlow';
  src: url('/assets/fonts/barlowsemicondensed-regularitalic-webfont.woff2') format('woff2'),
  url('/assets/fonts/barlowsemicondensed-regularitalic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
