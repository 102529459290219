.topic-page {
	.main-content, .results-wrapper {
		--padding-end: 0;
		--padding-start: 0;
	}
	.topic-summary {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 33%;
		padding: 20px;
		border-top: 1px solid var(--ion-color-medium-shade);
		border-bottom: 1px solid var(--ion-color-medium-shade);
		.follow-button-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.category-data {
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			margin: 5px 0 15px;
			font-size: 1.1rem;
			padding: 0 10px;
		}
	}
	h2 {
		margin-top: 0;
		font-weight: bold;
		text-align: center;
		padding: 0 10px;
	}
	.topic-list {
		--padding-top:0;
		height: 67%;
		h4 {
			padding: 10px 0 0;
			color: var(--ion-color-medium);
		}
	}
}
@import "responsive";
