#home .user-page {
  ion-content{
    --padding-top: 0;
  }
  .user-header {
    width: 100%;
    pointer-events: none;
    .user-stripe{
      background-color: var(--ion-color-primary);
      height: 4vw;
      width: 100%;
      left: 0;
      z-index: 0;
    }
    .user-profile-image-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -4vw;
      padding-left: var(--padding-start);
      padding-right: var(--padding-end);
      .profile-image {
        height: 25vw;
        width: 25vw;
        &.is-scrolled{
          width: 15vw;
          height: 15vw;
        }
      }
    }
  }
  .user-content {
    padding-top: 7vw;
    .follow-button-wrapper{
      justify-content: flex-end;
      padding-bottom: 7vw;
    }
    .user-info {
      margin: 2vw 0;
      p{
        margin-block-end: 0;
        margin-block-start: .5em;
      }
      .followers-statistics {
        display: flex;
        margin: 3vw 0 0;
        justify-content: flex-start;

        .followers-statistic {
          margin: 0 6vw 0 0;

          p {
            margin: 0;
          }
          strong{
            padding-right: 1vw;
          }
        }
      }
    }
  }
}
@import "responsive";
