.beta-page {
  h4, h6 {
    text-align: center;
    margin: 2.5vw 0 0;
  }
  h6 {
    color: var(--ion-color-primary);
    font-weight: bold;
    margin: 0;
  }
}
.pending-page {
  h4, h6 {
    text-align: center;
    margin: 2.5vw 0 0;
  }
  h5 {
    font-weight: bold;
    color: var(--ion-color-medium);
  }
  ion-button {
    margin-top: 40%;
  }
}
