.business-location-content-details {
  h6 {
    font-weight: bold;
    margin: 0;
  }
  .more-link {
    font-weight: bold;
    color: var(--ion-color-medium);
  }
  .no-results-message {
    margin: 20px auto;
    max-width: 60%;
  }
  .location-address-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    p {
      margin: 0;
    }

    ion-button {
      --background: transparent;
      --background-hover: transparent;
      width: auto;
      height: auto;
      --padding-end: 0;
      margin: 0 4vw 4vw;

      ion-icon {
        width: 32px;
        height: 32px;
        margin: 0;
        pointer-events: none;
      }
    }
  }
  .active-hour-title{
    color: var(--ion-color-secondary);
    font-weight:bold;
    &.closed{
      color: var(--ion-color-danger);
    }
  }
  .active-hour-title-arrow{
    color: var(--ion-color-primary);
  }
}
.location-action-sheet {
  .action-sheet-group {
    .action-sheet-button {
      padding: 10px;
      contain: initial;
      height: auto;

      .action-sheet-button-inner {
        flex-direction: row-reverse;
        color: black;
        font-size: 4vw;
        justify-content: flex-end;
        text-align: left;
        ion-icon {
          color: var(--ion-color-primary);
          font-size: 6vw;
        }
      }

      &.selected .action-sheet-button-inner {
        justify-content: space-between;
      }
    }
  }
  .action-sheet-group-cancel{
    .action-sheet-button {
      .action-sheet-button-inner {
        justify-content: center;
      }
    }
  }
}
@import "responsive";
