.color-selector {
  .color-squares {
    @media screen and (min-width: 457px) {
      .color-square {
        width: 32px;
        height: 32px;

        &.active {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}
