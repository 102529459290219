.post-response-page{
  ion-title {
    text-transform: capitalize;
  }
  ion-item {
    padding: 0 0 6vw;
    p {
      font-size: 3.75vw;
      color: black;
    }
    ion-button {
      width: 20vw;
      height: 9.375vw;
      visibility: visible;
      button {
        font-size: 4.0625vw;
      }
    }
  }
  .no-interaction{
    padding: 8vw 6.5vw;
    text-align: center;
    color: var(--ion-color-medium);
    h4{
      color: var(--ion-color-medium);
    }
    ion-icon{
      font-size: 50vw;
      padding: 6vw 0;
    }
    .subhead{
      font-weight: bold;
    }
  }
}
@import "responsive";
