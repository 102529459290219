.post-item-with-business {
  @media screen and (min-width: 600px) {
    --padding-top: 18px;
    --padding-bottom: 18px;
    .post-item-details {
      p {
        padding-left: 54px;
      }
      .post-location-details{
        p {
          padding-left: 12px;
        }
      }
      .profile-image {
        width: 42px;
        height: 42px;
      }
      .post-content {
        padding: 0 12px 0 0;
      }
      .post-preview-thumbnail {
        height: 162px;
      }
    }
  }
}
