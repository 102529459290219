.verification-code-entry {
  @media screen and (min-width: 600px) {
    h4 {
      font-size: 48px;
    }
    .phone-number-display {
      a {
        margin: 0 24px;
      }
    }
    .submit-line {
      margin: 24px auto;
    }
  }
}
