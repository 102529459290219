.welcome-desktop-menu {
  ion-content {
    --padding-top: 50px;
  }
  .account-switcher {
    margin: 0 0 0 20px;
    font-weight: bold;
    color: var(--ion-color-primary);
  }
  hr {
    background: var(--ion-color-light-shade);
    height: 1px;
  }
  ion-item {
    font-weight: bold;
    --color-hover: var(--ion-color-primary);
    cursor: pointer;
    ion-icon {
      margin: 0 10px 0 0;
    }
    &.active, &.active ion-icon  {
      color: var(--ion-color-primary);
    }
  }
}
