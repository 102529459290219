#welcome-section {
  .sign-up-page {
    .name-phone-signup {
      h3 {
        margin-top: 0;
        padding-bottom: 12vw;
        text-align: center;
        color: var(--ion-color-primary);
        font-size: 7vw;
      }
      ion-item {
        border: .3125vw var(--ion-color-primary) solid;
        margin-bottom: 3.125vw;
        --padding-start: 1.5625vw;
        &.age-checkbox {
          border: none;
          margin: 0;
          ion-checkbox {
            margin-inline-end: 10px;
            --border-color: var(--ion-color-primary);
          }
          p {
            font-size: 3.5vw;
          }
        }
      }
      .name-email-form-content {
        ion-input {
          --placeholder-color: var(--ion-color-primary);
          --placeholder-font-weight: bold;
          --placeholder-opacity: 1;
          --padding-start: 1.5625vw;
          margin: 0;
          --padding-end: 0;
          font-size: 5vw;
          font-style: normal;
        }
        ion-icon {
          color: var(--ion-color-medium);
          font-size: 7vw;
          margin: 0 0 0 1.5625vw;
        }
        .side-by-side {
          padding: 0;
          > * {
            width: 50%;
          }
          ion-item:first-child {
            margin-right: 1vw;
          }
          ion-item:last-child {
            margin-left: 1vw;
          }
        }
      }

      .disclaimer {
        font-size: 3.5vw;
        margin: 0 3.125vw;
      }
    }
  }
}

@import "responsive";
