.no-business-followers {
  text-align: center;
  ul {
    text-align: left;
    padding: 0 0 0 8vw;
  }
  .share-link-section {
    .location-url-display {
      padding: 2vw;
      border: 1px solid var(--ion-color-medium);
      border-radius: 3px;
      font-weight: bold;
      font-size: 3vw;
      color: var(--ion-color-secondary);
    }
    ion-button {
      width: 45vw;
      margin: 5vw 0 0;
    }
  }
}
.business-followers-list {
  .total-followers {
    color: black;
  }
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    margin: 5vw 0;
    .profile-image {
      margin: 0 5vw 0 0;
    }
    .follower-details {
      flex-grow: 1;
      p {
        margin: 0;
        font-size: 3.5vw;
        color: black;
      }
    }
  }
}

@import "responsive";
