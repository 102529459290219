#bio-editor-page {
  @media screen and (min-width: 600px) {

    ion-button {
      margin-top: 60px;
      font-size: 36px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
