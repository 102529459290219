.member-post-creator-page {
  .member-post-creator-form {
    height: 100%;
    &.scroll-locked {
      overflow: hidden;
    }
    ion-toolbar {
      --border-width: 0 !important;
    }
    .close-button {
      font-size: 24px;
    }
    ion-title {
      color: var(--ion-color-primary)
    }
    .submit-button {
      &.button-disabled {
        --ion-color-base: var(--ion-color-medium) !important;
      }
      --padding-start: 15px;
      --padding-end: 15px;
    }
    .post-form-content {
      --padding-bottom: 185px;
      .post-content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .member-information-header {
          --padding-start: 0;

          .member-information-bubble {
            color: var(--ion-color-primary-shade);
            border: 1px solid var(--ion-color-primary);
            padding: 2px 15px;
            border-radius: 2px;
          }
        }

        .italic-toggle {
          padding: 0 20px;
          line-height: 25px;

          &.italic {
            font-family: 'chewyregular';
            color: var(--ion-color-primary);
          }
        }
      }

      h4 {
        margin: 10px 0 0;
      }
      h4,h5 {
        font-size: 16px;
      }
      h5 {
        margin: 0 0 20px;
      }
      .dummy-input {
        height: 0;
        border: 0;
        padding: 0;
        margin: 0;
      }

      .rdw-editor-main {
        min-height: 110px;
        font-size: 18px;
        .public-DraftStyleDefault-block {
          margin: 0;
        }
        span[style*="italic"] {
          font-family: 'chewyregular';
          color: var(--ion-color-primary);
        }
      }

      .image-selector {
        display: flex;
        justify-content: flex-start;

        > div {
          width: 20vw;
          height: 20vw;
          border: 1vw solid white;
          margin: 0 2vw 0 0;
          border-radius: 10px;
          background-size: cover;

          .asset-uploader {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.selected {
            border: 1vw solid var(--ion-color-success)
          }

          &.image-uploader-placeholder {
            background: white;
            border: 2px solid var(--ion-color-primary);

            ion-icon {
              font-size: 12vw;
              color: var(--ion-color-primary);
            }
          }
        }
      }
    }
    .bottom-sticky-section {
      background: white;
      border-top: 2px solid var(--ion-color-medium);
      padding: 0 20px;
      p {
        text-align: center;
        font-family: 'chewyregular';
        color: var(--ion-color-primary);
      }
    }
  }
}
@import "responsive";
